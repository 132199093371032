import { useContext, useEffect, useRef } from 'react';
import { AllDigital, Button, DigitalAdvantages, OnlineServices, QuestionsService, ServiceSteps } from '../../components';
import './styles.scss';
import { AppContext } from '../../context/AppContext';
import { Helmet } from 'react-helmet';

const Revision: React.FC = () => {
  const circle1 = useRef<HTMLDivElement>(null);
  const circle2 = useRef<HTMLDivElement>(null);
  const circle3 = useRef<HTMLDivElement>(null);
  const circle4 = useRef<HTMLDivElement>(null);
  const circle5 = useRef<HTMLDivElement>(null);
  const circle6 = useRef<HTMLDivElement>(null);
  const circle7 = useRef<HTMLDivElement>(null);
  const line1 = useRef<HTMLDivElement>(null);
  const line2 = useRef<HTMLDivElement>(null);
  const line3 = useRef<HTMLDivElement>(null);
  const line4 = useRef<HTMLDivElement>(null);
  const line5 = useRef<HTMLDivElement>(null);
  const line6 = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'Revisión de Etiquetado';
    }
  }, []);

  useEffect(() => {
    const cambiarClase = () => {
      if (circle1.current) {
        circle1.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle1.current) {
              circle1.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle2.current) {
        circle2.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle2.current) {
              circle2.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle3.current) {
        circle3.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle3.current) {
              circle3.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle4.current) {
        circle4.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle4.current) {
              circle4.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle5.current) {
        circle5.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle5.current) {
              circle5.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle6.current) {
        circle6.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle6.current) {
              circle6.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (circle7.current) {
        circle7.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
        setTimeout(
          () => {
            if (circle7.current) {
              circle7.current.classList.toggle('constancy__list__animation-container__steps__container__circle-container__circle--animation');
            }
          },
          20);
        ;
      }
      if (line1.current) {
        line1.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line1.current) {
              line1.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line2.current) {
        line2.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line2.current) {
              line2.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line3.current) {
        line3.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line3.current) {
              line3.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line4.current) {
        line4.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line4.current) {
              line4.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line5.current) {
        line5.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line5.current) {
              line5.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      if (line6.current) {
        line6.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
        setTimeout(
          () => {
            if (line6.current) {
              line6.current.classList.toggle('constancy__list__animation-container__steps__container__vertical-line__line--animation');
            }
          },
          20);
        ;
      }
      timeoutRef.current = setTimeout(cambiarClase, 16000);
    };

    cambiarClase();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  const {
    setModalOpen,
    modalOpen
  } = useContext(AppContext);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Solicitar la revisión del etiquetado de tu producto para evitar multas, sanciones o inmovilizaciones"
        />
      </Helmet>
      <section className='revision-service'>
        <div className='revision-service__header'>
          <div className='revision-service__header__top'>
            <div className='revision-service__header__top__item'>Para fabricantes de productos</div>
          </div>
          <div className='revision-service__header__info'>
            <div className='revision-service__header__info__left'>
              <h1>Revisión de Etiquetado</h1>
              <p>Solicitar la revisión del etiquetado de tu producto para evitar multas, sanciones o inmovilizaciones.</p>
              <Button text={'Solicitar'} onClick={() => setModalOpen(!modalOpen)} type={'ligth'} />
            </div>
            <div className='revision-service__header__info__image-container'>
              <img className='revision-service__header__info__image-container__image' src="/img/revision-service.svg" alt="revision-service" />
            </div>
          </div>
        </div>
        <div className='revision-service__legal'>
          <h2>Cumple con las normas en México</h2>
          <div className='revision-service__legal__section-1'>
            <p>Las autoridades en México cuentan con las facultades para revisar el etiquetado de productos de manera aleatoria, tanto en establecimentios como supermercados, farmacias, tienda de coneniencia, etc. Por lo que es importante estar al día con las regulaciones y evitar que te envíen un requerimiento para modificar el etiquetado de tu producto.</p>
            <p>Muchos de los productos en México cuentan con publicidad engañosa o que no pueden comprobar, por lo que nuestro grupo de expertors puede decirte que es lo que puedes o no colocar en las etiquetas de tus productos.
              <br />
              <br />
              De esta manera podrás aventajar a tu competencia.
            </p>
          </div>
          <div className='private-warehouse__legal__section-2'>
            <div className='private-warehouse__legal__section-2__item'>
              <img src="/img/legal-1.svg" alt="legal-1" />
              <h3>Presencia Nacional</h3>
              <p>Contamos con un equipo de personas en todo México que podrá apoyarte y hacer las inspecciones de etiquetado.</p>
            </div>
            <div className='private-warehouse__legal__section-2__item'>
              <img src="/img/legal-2.svg" alt="legal-2" />
              <h3>Trabajamos con tu agente aduanal</h3>
              <p>para facilitar el trabajo y gestión de los requisitos que te solicita la aduana.</p>
            </div>
            <div className='private-warehouse__legal__section-2__item'>
              <img src="/img/legal-3.svg" alt="legal-3" />
              <h3>Acreditación y Aprobación</h3>
              <p>En CECSA contamos con las licencias correspondientes de acuerdo con la Ley de la Infraestructura de la Calidad.
                <br />
                <br />
                Trabaja sólo con empresas confirables.
              </p>
            </div>
          </div>
        </div>
        <AllDigital />
        <DigitalAdvantages />
        <div className='constancy__fundament'>
          <h2>Fundamentos</h2>
          <p className='constancy__fundament__text'>
            DECRETO por el que se expide la <b>Ley de Infraestructura de la Calidad</b> y se abroga la Ley Federal sobre Metrología y Normalización
            <br />
            <br />
            De los Organismos de Evaluación de la Conformidad
            <br />
            <br />
            Artículo 53.<br />
            Los Organismos de Evaluación de la Conformidad podrán operar como:<br />
            I. Laboratorios, de ensayos y pruebas, medición o calibración, entre otros;
            <br />
            <br />
            <b>II. Unidades de inspección;</b>
            <br />
            <br />
            III. Organismos de certificación, y
            <br />
            <br />
            IV. Otros proveedores y prestadores de servicios previstos en el Reglamento de esta Ley.
            <br />
            <br />
            <b>Artículo 55.</b> Una vez obtenida la acreditación, las personas interesadas en operar como Organismos de Evaluación de laConformidad para la Evaluación de la Conformidad de Normas Oficiales Mexicanas, Estándares, Normas Internacionales ahíreferidos o de otras disposiciones legales, deberán presentar la solicitud de aprobación ante la Autoridad Normalizadora de quese trate,
            <br />
            <br />
            <b>Artículo 62.</b> La Evaluación de la Conformidad, comprende el proceso técnico de demostración de cumplimiento con lasNormas Oficiales Mexicanas o con Estándares.
            <br />
            <br />
            La Evaluación de la Conformidad podrá realizarse por tipo, línea, lote o partida de bienes, o por sistema, ya sea directamenteen las instalaciones que correspondan o durante el desarrollo de las actividades, servicios o procesos de que se trate.
            <br />
            <br />
            <b>Artículo 68.</b> Las autoridades competentes deberán reconocer los resultados de la Evaluación de la Conformidad realizadospor los Organismos de Evaluación de la Conformidad
            <br />
            <br />

            <b></b>Constancia <br />
            Documento que se emite a los productores, fabricantes, importadores, comercializadores o prestadores de servicios como resultado de la evaluación de la conformidad realizada a una etiqueta en el que se evidencia el cumplimiento o no cumplimiento de los requisitos establecidos en las Normas Oficiales Mexicanas, cuando sea aplicable de conformidad con el procedimiento establecido en el Acuerdo por el que la Secretaría de Economía emite reglas y criterios de carácter general en materia de Comercio Exterior.
          </p>
          <div className='constancy__fundament__image-container'>
            <img src="/img/fundament.svg" alt="Basis" className='constancy__fundament__image-container__image' />
          </div>
        </div>
        <OnlineServices />
        <ServiceSteps
          withNewsLetter={true}
        />
        <QuestionsService
          questions={[
            {
              question: '¿Cuánto cuesta una revisión de etiquetado?',
              response: <>Dependerá del numero de modelos o constancias que requieras, acércate con uno de nuestros especialistas para que podamos darte una atención personalizada</>
            },
            {
              question: '¿Qué necesito para obtener una revisión de etiquetas?',
              response: <>Requiere darte de alta con nosotros, a través de un contrato de servicios. Una vez que el contrato este firmado podrás enviar la información de tu producto como puede ser, imágenes, archivos PDF, archivos editables, medidas del envase, etc.</>
            },
            {
              question: '¿Cuánto tiempo toma obtener una revisión de etiquetado?',
              response: <>Nuestros tiempos varían en función a la cantidad de etiquetas que requieras revisión. Sugerimos preguntar a un asesor.</>
            },
            {
              question: '¿Puedo solicitar una revisión urgente?',
              response: <>Si, una vez que ya tengas el contrato firmado puedes solicitar el servicio urgente, el cual se entrega al siguiente día hábil. </>
            },
            {
              question: '¿Puedo tramitar una constancia posterior a la revisión?',
              response: <>Si, puedes solicitar una constancia posterior a la revisión, solamente que se cobraría un extra por la emisión de la constancia</>
            },
            {
              question: '¿Necesito tramitar una revisión por cada producto?',
              response: <>Si, ya que la revisión ampara 1 producto y en caso de que tengas varias presentaciones es requerido que las evaluemos todas para no dejar huecos y que cumplas con la norma en todos sus requisitos. </>
            },
            {
              question: '¿Cuántas veces pueden revisar mi producto por cada servicio?',
              response: <>Una vez liquidado el servicio tienes derecho a 3 revisiones por cada producto.</>
            },
            {
              question: '¿Es necesario que les envíe una muestra de mi producto?',
              response: <>No es necesario, ya que podrás enviar toda la información del producto como medidas, tamaños, imágenes, archivos PDF, archivos editables – en los que venga toda la información de tu producto.</>
            },
            {
              question: '¿Quién respalda el trabajo de CECSA? ',
              response: <>CECSA cuenta con acreditación por parte de la Entidad Mexicana de Acreditación y cuenta con Aprobación por parte de la Dirección General de Normas de la Secretaría de Economía, lo que permite que tengamos conocimientos y competencia técnica para poderte resolver tus dudas y que te guiemos por el mejor camino para que cumplas con los requisitos regulatorios de las Normas Oficiales Mexicanas</>
            }
          ]}
        />
      </section>
    </>
  )
}

export default Revision