import './styles.scss';

const OnlineServices: React.FC = () => {
  return (
    <section className='online-services'>
      <h2>Solicita servicios en línea</h2>
      <p className='online-services__text'>
        Apoyate de la tecnología y de nuestro sistema en línea para que puedas ahcer más fácil tus operaciones de comercio exterior.
        <br />
        <br />
        <img src="/img/check-mark.svg" alt="online-services" className='online-services__text__check' /> Ingresa tus solicitud de servicios
        <br />
        <br />
        <img src="/img/check-mark.svg" alt="online-services" className='online-services__text__check' /> Obtén tus folios de Secretaría de Economía
        <br />
        <br />
        <img src="/img/check-mark.svg" alt="online-services" className='online-services__text__check' /> Revisa cuando salgan validados
        <br />
        <br />
        <img src="/img/check-mark.svg" alt="online-services" className='online-services__text__check' /> Sube tu pedimento pagado
        <br />
        <br />
        <img src="/img/check-mark.svg" alt="online-services" className='online-services__text__check' /> Solicita tu visita de inspección
        <br />
        <br />
        <img src="/img/check-mark.svg" alt="online-services" className='online-services__text__check' /> ¡Listo! Ya puedes comercializar tus productos
        <br />
        <br />
      </p>
      <div className='online-services__image-container'>
        <img src="/img/online-services.gif" alt="online-services" className='online-services__image-container__image' />
      </div>
    </section>
  )
}

export default OnlineServices