import Button from '../Button';
import './styles.scss';

const Error404: React.FC = () => {

  return (
    <div className='error-404'>
      ¡Oops! Aquí no hay nada
      <Button text={'Regresar'} onClick={() => { }} type={'black'} href='/' anchor={true} target='_self' />
    </div>
  )
}

export default Error404