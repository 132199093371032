import { useContext, useEffect } from 'react';
import { AllDigital, Button, DigitalAdvantages, JsonLd, OnlineServices, QuestionsService, ServiceSteps } from '../../components';
import { AppContext } from '../../context/AppContext';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Constancy: React.FC = () => {
  const {
    setModalOpen,
    modalOpen
  } = useContext(AppContext);

  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'Constancia de inspección';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/constancy",
            "url": "https://www.cecsatrade.mx/constancy",
            "name": "Constancia",
            "description": "Servicio emitido a importadores: Cuando quieran cumplir con el etiquetado antes del despacho aduanero, es requisito presentar la mercancía ya etiquetada.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-01-31T08:08:40-06:00",
            "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/img/icons/constancy.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Documento que respalda que tú producto se encuentre correctamente etiquetado. Evita multas y sanciones en la aduana"
        />
      </Helmet>
      <section className='constancy'>
        <div className='constancy__header'>
          <div className='constancy__header__top'>
            <div className='constancy__header__top__item'>Para Importadores</div>
            <div className='constancy__header__top__item'>Para Fabricantes Nacionales</div>
          </div>
          <div className='constancy__header__info'>
            <div className='constancy__header__info__left'>
              <h1>Constancia de inspección</h1>
              <p>Documento que respalda que tú producto se encuentre correctamente etiquetado. Evita multas y sanciones en la aduana</p>
              <Button text={'Solicitar'} onClick={() => setModalOpen(!modalOpen)} type={'black'} />
            </div>
            <div className='constancy__header__info__image-container'>
              <img className='constancy__header__info__image-container__image' src="/img/constancy-header.svg" alt="constancy" />
            </div>
          </div>
        </div>
        <div className='constancy__legal'>
          <h2>Olvidate de multas y retrasos en la aduana</h2>
          <div className='constancy__legal__section-1'>
            <p>Con una constancia emitida por CECSA podrás cruzar tu mercancía por la aduana al mismo tiempo que evitas demoras por incumplimiento de etiquetado.
              <br />
              <br />
              Adelantate y antes de que tú proveedor envíe la mercancía a la audana, etiquetala. De esa manera no tendrás retrasos.
            </p>
            <p>Ideal para <b>Importadores</b> que quieren contar con su empaque especial para México. O para aquellos importadores que van a colocar una etiqueta en su producto previo despacho.
              <br />
              <br />
              Ideal para <b>Fabricantes Nacionales</b> que quieren sacar su producto al mercado cumpliendo las normas de etiquetado desde el inicio.</p>
          </div>
          <div className='constancy__legal__section-2'>
            <div className='constancy__legal__section-2__item'>
              <img src="/img/legal-1.svg" alt="legal-1" />
              <h3>Presencia Nacional</h3>
              <p>Contamos con un equipo de personas en todo México que podrá apoyarte y hacer las inspecciones de etiquetado.</p>
            </div>
            <div className='constancy__legal__section-2__item'>
              <img src="/img/legal-2.svg" alt="legal-2" />
              <h3>Trabajamos con tu agente aduanal</h3>
              <p>para facilitar el trabajo y gestión de los requisitos que te solicita la aduana.</p>
            </div>
            <div className='constancy__legal__section-2__item'>
              <img src="/img/legal-3.svg" alt="legal-3" />
              <h3>Acreditación y Aprobación</h3>
              <p>En CECSA contamos con las licencias correspondientes de acuerdo con la Ley de la Infraestructura de la Calidad.
                <br />
                <br />
                Trabaja sólo con empresas confirables.
              </p>
            </div>
          </div>
        </div>
        <AllDigital />
        <DigitalAdvantages />
        <div className='constancy__fundament'>
          <h2>Fundamentos</h2>
          <p className='constancy__fundament__text'>
            ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.
            <br />
            <br />
            2.4.8 Para las mercancías que se listan en el numeral 3 del Anexo 2.4.1 del presente ordenamiento, únicamente se exigirá que las etiquetas o los medios adheribles permitidos, contengan la información establecida en la NOM correspondiente, y que al momento de su introducción al territorio nacional se encuentren adheridas, pegadas, cosidas, colgadas o colocadas en las mercancías como se establezca en cada una de las normas, de tal modo que impida su desprendimiento inmediato, y asegure su permanencia en las mismas hasta llegar al usuario.
            <br />
            <br />
            I. Presentar a despacho aduanero, acompañadas del documento original o copia de la constancia de conformidad expedida por una unidad de verificación o de inspección acreditada y aprobada en los términos de la Ley de la Infraestructura de la Calidad
            <br />
            <br />
            <b>Constancia</b> <br />
            Documento que se emite a los productores, fabricantes, importadores, comercializadores o prestadores de servicios como resultado de la evaluación de la conformidad realizada a una etiqueta en el que se evidencia el cumplimiento o no cumplimiento de los requisitos establecidos en las Normas Oficiales Mexicanas, cuando sea aplicable de conformidad con el procedimiento establecido en el Acuerdo por el que la Secretaría de Economía emite reglas y criterios de carácter general en materia de Comercio Exterior.

          </p>
          <div className='constancy__fundament__image-container'>
            <img src="/img/fundament.svg" alt="Basis" className='constancy__fundament__image-container__image' />
          </div>
        </div>
        <OnlineServices />
        <ServiceSteps
          withNewsLetter={true}
        />
        <QuestionsService
          questions={[
            {
              question: '¿Cuánto cuesta una constancia?',
              response: <>Dependerá del numero de modelos o constancias que requieras, acércate con uno de nuestros especialistas para que podamos darte una atención personalizada</>
            },
            {
              question: '¿Qué necesito para obtener una constancia?',
              response: <>Requiere darte de alta con nosotros, a través de un contrato de servicios. Una vez que el contrato este firmado podrás enviar la información de tu producto como puede ser, imágenes, archivos PDF, archivos editables, medidas del envase, etc.</>
            },
            {
              question: '¿Cuánto tiempo toma obtener una constancia?',
              response: <>Puede ser variable. En caso de que la etiqueta de producto ya se encuentre correcta puedes obtener la constancia el mismo día solicitando el servicio urgente.
                <br />
                <br />
                En caso de que tu etiqueta o envase requieran correcciones deberás de aplicarlas para enviarlo nuevamente a revisión.</>
            },
            {
              question: '¿Puedo solicitar una revisión urgente?',
              response: <>Si, una vez que ya tengas el contrato firmado puedes solicitar el servicio urgente, el cual se entrega al siguiente día hábil. </>
            },
            {
              question: '¿Cuánto tiempo de vigencia tiene la constancia?',
              response: <>La constancia tiene vigencia indefinida, es decir, siempre podrás usarla para tus importaciones o para respaldar tu producto.
                <br />
                <br />
                Solamente pierde vigencia en caso de que: Cambie la norma o hagas modificaciones al envase como, por ejemplo, tamaño, contenido, ingredientes, información nutrimental.</>
            },
            {
              question: '¿De qué me protege la constancia?',
              response: <>La constancia ampara el cumplimiento de la norma oficial mexicana en la que hayas solicitado el servicio. Es tu seguro para evitar inmovilizaciones, multas por parte de las autoridades como PROFECO o COFEPRIS.</>
            },
            {
              question: '¿Necesito tramitar una constancia por cada producto?',
              response: <>Si, ya que la constancia ampara 1 producto y en caso de que tengas varias presentaciones es requerido que las evaluemos todas para no dejar huecos y evitar que te cuestionen.</>
            },
            {
              question: '¿Qué sucede si ya tengo mi constancia y tengo que hacer modificaciones al envase del producto?',
              response: <>Deberás de solicitar una nueva constancia, ya que necesitamos evaluar los cambios de acuerdo con las Normas Oficiales Mexicanas (NOM’s)</>
            },
            {
              question: '¿Cuántas veces pueden revisar mi producto por cada servicio?',
              response: <>Una vez liquidado el servicio tienes derecho a 3 revisiones por cada producto.
                <br />
                En caso de que el producto cumpla en cualquiera de las 3 revisiones – se emitirá la constancia de cumplimiento
                <br />
                <br />
                Por el contrario, en caso de que el producto no cumpla en la 3ra revisión – se emitirá una negación de constancia y deberás de solicitar nuevamente el servicio con otro número de folio.</>
            },
            {
              question: '¿Es necesario que les envíe una muestra de mi producto?',
              response: <>No es necesario, ya que podrás enviar toda la información del producto como medidas, tamaños, imágenes, archivos PDF, archivos editables – en los que venga toda la información de tu producto.</>
            },
            {
              question: '¿Quién respalda el trabajo de CECSA? ',
              response: <>CECSA cuenta con acreditación por parte de la Entidad Mexicana de Acreditación y cuenta con Aprobación por parte de la Dirección General de Normas de la Secretaría de Economía, lo que permite que tengamos conocimientos y competencia técnica para poderte resolver tus dudas y que te guiemos por el mejor camino para que cumplas con los requisitos regulatorios de las Normas Oficiales Mexicanas</>
            }
          ]}
        />
      </section>
    </>
  )
}

export default Constancy