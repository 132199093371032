/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom142: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-173-SE-2021 - Jugos';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {

            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-173-se-2021",
            "url": "https://www.cecsatrade.mx/nom-173-se-2021",
            "name": "NOM-173-SE-2021",
            "description": "La NOM-173-SE-2021, que regula los jugos, agua de coco, néctar, bebidas no alcohólicas con un contenido vegetal o frutal y bebidas saborizadas a vegetales, frutas u hortalizas, asegurando que los productos cumplan con los estándares de calidad, composición y etiquetado requeridos en el mercado mexicano.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-01-31T08:08:40-06:00",
            "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/173.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-173-SE-2021, que regula los jugos, agua de coco, néctar, bebidas no alcohólicas con un contenido vegetal o frutal y bebidas saborizadas a vegetales, frutas u hortalizas, asegurando que los productos cumplan con los estándares de calidad, composición y etiquetado requeridos en el mercado mexicano."
        />
      </Helmet>
      <section className='norm-info'>

        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-173-SE-2021 JUGOS Y BEBIDAS NO ALCOHÓLICAS PREENVASADAS</h1>
        <div className='norm-info__header norm-info__header--173'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>Norma de jugos, néctares y bebidas no alcohólicas con contenido vegetal o néctar de frutas</b> tiene por objeto establecer las denominaciones e información comercial para el correcto etiquetado de los productos preenvasados de fabricación nacional o extranjeras destinados al consumidor final.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> establece las disposiciones necesarias para garantizar información precisa y eficaz protegiendo al consumidor dentro de la calidad de estas bebidas a <b>base de jugos de frutas</b>.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              La <b>NOM-173-SE-2021</b>, que <b>regula los jugos, agua de coco, néctar, bebidas no alcohólicas con un contenido vegetal o frutal y bebidas saborizadas a vegetales, frutas u hortalizas</b>, asegurando que los productos cumplan con los estándares de calidad, composición y etiquetado requeridos en el mercado mexicano.
            </p>
          </div>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/bottle-173.png" alt="bottle" />
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-173-SE-2021</b> de <b>Jugos, agua de coco, néctares, bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza y bebidas saborizadas no alcohólicas preenvasadas -Denominaciones-Especificaciones-Información comercial y métodos de prueba</b>, se publicó el 28 de febrero de 2022 en el Diario Oficial de la Federación pero entró en vigor el 27 de agosto de 2022.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/nota_detalle.php?codigo=5643986&fecha=28/02/2022#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />

        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación</h2>
            <br />
            <p>
              Esta norma es aplicable a todos los <b>productos nacionales</b> y de procedencia extranjera  destinados a comercialización.
              <br />
              <br />
              Los productos a los que se refiere esta <b>Norma Oficial Mexicana</b> deben seguir lo indicado en las secciones 2.4, 2.6 y 2.7. Pueden utilizar los aditivos y sustancias permitidas según la sección 2.10, además de cumplir con las siguientes especificaciones o nombres.
              <br />
              <div className='norm-info__image-container__aplications-173'>
                <div className='norm-info__image-container__aplications-173__item'>
                  <img loading="lazy" src="/img/jabones.svg" alt="jabones" />
                  <span>Jugos</span>
                </div>
                <div className='norm-info__image-container__aplications-173__item'>
                  <img loading="lazy" src="/img/desinfectantes.svg" alt="desinfectantes" />
                  <span>Agua de coco</span>
                </div>
                <div className='norm-info__image-container__aplications-173__item'>
                  <img loading="lazy" src="/img/detergentes.svg" alt="detergentes" />
                  <span>Bebidas saborizadas no alcohólicas</span>
                </div>
                <div className='norm-info__image-container__aplications-173__item'>
                  <img loading="lazy" src="/img/limpiadores.svg" alt="limpiadores" />
                  <span>Néctares</span>
                </div>
                <div className='norm-info__image-container__aplications-173__item'>
                  <img loading="lazy" src="/img/limpiadores.svg" alt="limpiadores" />
                  <span>Bebidas no alcohólicas con vegetal o fruta</span>
                </div>
              </div>
              <br />
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <br />
        <br />
        <br />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-173-SE-2021 no aplica para</h2>
            <br />
            <p style={{ textAlign: 'center' }}>La presente <b>Norma Oficial Mexicana</b> no es aplicable a los siguientes productos:</p>
            <br />
            <div className='norm-info__image-container__text__data-item-container'>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/cafe.svg" alt="café" />
                <span>Café</span>
              </div>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/requires-liquid.svg" alt="requiere liquido" />
                <span>Productos que requieren otro líquido</span>
              </div>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/granel-173.svg" alt="granel" />
                <span>Productos a granel</span>
              </div>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/leche.svg" alt="leche" />
                <span>Leche</span></div>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/yogurth.svg" alt="yogurth" />
                <span>Yogurth</span></div>
            </div>
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--020'>
          <img loading="lazy" src="/img/bottles-173.png" alt="bottles-173" style={{ margin: '5rem auto', marginTop: '0', maxWidth: '90vw' }} />
          <br />
          <br />
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Adulteración</span>
            <p>Producto cuya naturaleza y composición no corresponda a aquella con que se etiquete, anuncie o por no cumplir con las especificaciones descritas en esta NOM.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Agua de coco</span>
            <p>Líquido que se extrae directamente del fruto (Cocos nucifera L.) sin exprimir la pulpa.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Agua de coco concentrada</span>
            <p>Aquella donde se ha eliminado al menos en un 50% más que el valor Brix establecido para el producto obtenido y que ha sido sometido al tratamiento y condiciones de almacenamiento adecuadas que aseguren su conservación en el envase.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Agua de coco concentrado</span>
            <p>Agua de coco elaborada a partir de agua de coco concentrada.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Azúcares añadidos</span>
            <p>Azúcares libres agregados a los alimentos y a las bebidas no alcohólicas durante la elaboración</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Azúcares libres</span>
            <p>Monosacáridos y disacáridos añadidos a los alimentos y a las bebidas no alcohólicas más los azúcares que están presentes naturalmente en miel y jarabes de maíz de alta fructuosa, entre otros jarabes similares.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza</span>
            <p>Líquidos naturales o transformados que proporcionan al organismo nutrimentos y que contienen un porcentaje mayor o igual al 10% en su composición final de vegetales o fruta, agua de coco o coco u hortaliza, en combinación, así como uso de extractos naturales, incluyendo soya, arroz, y otros del reino vegetal.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Bebidas saborizadas no alcohólicas</span>
            <p>Líquidos naturales o transformados que proporcionan al organismo nutrimentos y que contienen un porcentaje menor al 10% en su composición final de vegetales o fruta, agua de coco o coco, u hortaliza, o que no contengan porcentaje alguno y hagan uso de extractos o saborizantes naturales o artificiales de vegetal o fruta, agua de coco o coco, u hortaliza, en combinación, así como uso de extractos naturales, incluyendo soya, arroz, y otros del reino vegetal.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Edulcorantes</span>
            <p>Sustancias diferentes de los monosacáridos y de los disacáridos, que imparten un sabor dulce a los productos.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Frutas cítricas</span>
            <p>Frutas de la familia Rutaceae que se dañan con bajas temperaturas y se caracterizan porque tienen aceites y pigmentos en su cáscara.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Frutas sanas</span>
            <p>Aquellas libres de enfermedades, heridas, pudriciones, daños producidos por insectos u otras plagas, libres de insectos vivos o muertos o sus larvas.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Frutas o vegetales u hortalizas maduras</span>
            <p>Aquellas que han alcanzado el grado de desarrollo adecuado para su consumo (que están en su punto de sazón o madurez).</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Grados Brix</span>
            <p>Es el porcentaje de sólidos disueltos en un producto.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Jugo</span>
            <p>Producto líquido fermentable, obtenido al exprimir vegetales o frutas u hortalizas en buen estado, debidamente maduras y frescas en buen estado y sometido al tratamiento adecuado que asegura su conservación en el envase.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Jugo concentrado de vegetales o frutas u hortalizas</span>
            <p>Es al que se les ha eliminado físicamente el agua en una cantidad suficiente para elevar el nivel de grados Brix al menos en un 50% más que el valor establecido para el producto líquido obtenido al exprimir vegetales o frutas u hortalizas.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Jugo de vegetales o frutas u hortalizas de concentrados</span>
            <p>Jugo elaborado a partir de jugo concentrado de vegetales o frutas u hortalizas.</p>
          </div>
          <img loading="lazy" src="/img/bottles-173-2.png" alt="bottles-173 2" style={{ zIndex: '9', margin: '0 auto', maxWidth: '90vw' }} />
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>
        <BenefictsAndWhoDifferent title={'CERTIFICA TUS PRODUCTOS'} text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'} paddingTop='30rem' />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5263188&fecha=10/08/2012#gsc.tab=0" target='_blank'>NOM-002-SCFI-2011</a> Productos preenvasados-Contenido neto-Tolerancias y métodos de verificación.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a> Sistema General de Unidades de Medida.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0" target='_blank'>NOM-030-SCFI- 2006</a> Información comercial-Declaración de cantidad en la etiqueta-Especificaciones.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://www.cecsatrade.mx/nom-051-scfi-ssa1-2010" target='_blank'>NOM-051-SCFI/SSA1-2010</a> Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-Información comercial y sanitaria.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4890075&fecha=26/06/1996#gsc.tab=0" target='_blank'>NOM-086-SSA1-1994</a> Bienes y Servicios. Alimentos y bebidas no alcohólicas con modificaciones en su composición. Especificaciones nutrimentales.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4901457&fecha=21/11/1997#gsc.tab=0" target='_blank'>NOM-130-SSA1-1995</a> Bienes y servicios. Alimentos envasados en recipientes de cierre hermético y sometidos a tratamiento térmico. Disposiciones y especificaciones sanitarias.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://dof.gob.mx/normasOficiales/4643/salud/salud.htm" target='_blank'>NOM-218-SSA1-2011</a> Productos y servicios. Bebidas saborizadas no alcohólicas, sus congelados, productos concentrados para prepararlas y bebidas adicionadas con cafeína. Especificaciones y disposiciones sanitarias.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://www.dof.gob.mx/normasOficiales/3980/salud/salud.htm" target='_blank'>NOM-251-SSA1-2009</a> Prácticas de higiene para el proceso de alimentos, bebidas o suplementos alimenticios.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-173.png" alt="cinturón" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5095187&fecha=19/06/2009#gsc.tab=0" target='_blank'>NMX-F-103-NORMEX-2009</a> Alimentos-Determinación de grados Brix en alimentos y bebidas, Método de ensayo (prueba).
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='28/02/2022'
          VigorDate='27/08/2022'
          responsible='SECRETARÍA DE ECONOMIA'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Cuánto porcentaje esta permitido para que tenga agua o extracto de jugo?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El contenido varea de acuerdo al producto que se presente y a la fruta que se utilice, por ejemplo el contenido de jugo de vegetales o frutas u hortalizas debe ser del 100 %, o si es néctar varia respecto al tipo de fruta, como manzana y naranja tiene que tener un 50% de néctar.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Para qué productos no aplica la NOM?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  No aplica para productos de café que requieran una preparación de percolación o extracción con agua y se comercialice como bebida no alcohólica preenvasada y para productos que requieran de un modo de preparación a través de adicionar agua u otro líquido natural o transformado. También, para aquellos productos que entren en la <b>NOM-155-SCFI-2012</b> y la <b>NOM-181-SCFI/SAGARPA-2018</b> y para productos que estén en venta a granel o que se utilicen como materia prima para la elaboración de otros alimentos o bebidas no alcohólicas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Cómo se realizan las pruebas de calidad por parte de las Unidades de Inspección Acreditadas según la NOM-173-SE-2011?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Para emitir un Dictamen de cumplimiento, las Unidades de Inspección deben observar que el importador cumpla con las especificaciones  de la NOM de etiquetado para alimentos. Además que la información comercial este correcta y que  no induzca al engaño del consumidor. Y que cumplan con las siguientes denominaciones: Jugos, agua de coco, néctares, bebidas no alcohólicas con vegetal o fruta u hortaliza y bebidas saborizadas no alcohólicas
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿La NOM-173-SE-2011 aplica tanto para jugos naturales como para néctares?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Si, esta norma aplica para jugos naturales y néctares, independientemente de su contenido de fruta o de los aditivos que contengan.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Cómo afecta la NOM-173-SE-2011 a los productores de jugos?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los productores de jugos deben asegurarse de que el contenido del producto que utilizan cumplen con las especificaciones técnicas de la norma para garantizar la seguridad del producto y evitar problemas legales.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Qué requisitos de etiquetado deben cumplir los jugos bajo la NOM-173-SE-2011?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los requisitos que deben cumplir ciertas especificaciones fisicoquímicas de acuerdo a la fruta que se trate.
                  <br />
                  <br />
                  - Además, se debe declarar la denominación del producto se indica de lo que esta hecho el producto como "Jugo de Naranja", "Néctar de Mango", "Agua de Coco", etc. pero si el producto es una mezcla de jugos o néctares, debe especificar las frutas o vegetales que lo componen.
                  <br /> -Contenido neto del producto
                  <br /> -Lista de ingredientes
                  <br /> -Declaración nutrimental
                  <br /> -Leyendas precautorias
                  <br /> -Fecha de caducidad y instrucciones de conservación
                  <br /> -Datos del fabricante o importador
                  <br /> -Código de barras
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Quién es responsable de verificar el cumplimiento de la NOM-173-SE-2011?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los responsables de verificar el cumplimiento son las Unidades de Inspección Acreditadas, ya que para realizar el cumplimiento de esta norma te invitamos a acercarte a una unidad de inspección acreditada, con CECSA te brindamos el apoyo de revisar la información comercial de tu mercancía  para proporcionarte el etiquetado correcto  con base a esta normativa  y así al ser verificado el producto, se podrán imprimir las etiquetas y reetiquetar en el caso de que lleve algún sello o leyenda emitiendo como resultado final  un dictamen de cumplimiento para que tu mercancía teniendo en cuenta las fechas que sean indicadas  para  no tener problema alguno.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Qué sanciones tiene el incumplimiento de la NOM-173-SE-2011?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo a la Ley  Federal de la Metrología y Normalización, en el articulo 112, nos dice que si un producto no cumple con la NOM puede generar multas, clausura temporal o definitiva, que podrá ser parcial o total, arresto hasta por treinta y seis horas, suspensión o revocación de la autorización, aprobación, o registro según corresponda y suspensión o cancelación del documento donde consten los resultados de la evaluación de la conformidad, así como de la autorización del uso de contraseñas y marcas registradas.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        {/* <h1 className='norm-info__title'>NOM-173-SE-2021</h1>
        <h2>Jugos, agua de coco, néctares, bebidas no alcohólicas con contenido de vegetal o fruta uhortaliza y bebidas saborizadas no alcohólicas preenvasadas -Denominaciones-Especificaciones-Información comercial y métodosde prueba.</h2>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text'>
            <h2>Objetivo y campo de aplicación</h2>
            <p>
              La presente Norma Oficial Mexicana tiene por objeto establecer las especificaciones de las denominaciones de producto y la información comercial que debe contener el etiquetado de los productos preenvasados destinados al consumidor final, de fabricación nacional o extranjera, que se comercialicen en territorio nacional con las denominaciones de jugo, agua de coco, néctar, bebidas no alcohólicas con un contenido de vegetal o fruta u hortaliza y bebidas saborizadas no alcohólicas haciendo referencia a sabores de vegetales o frutas u hortalizas.
            </p>
          </div>
          <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-173.svg" alt="NOM 173 Logo" />
        </div>

        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">

              <h2 className="norm-info__container__sub-title" id="no-aplica">No aplica a</h2>
              <p className="norm-info__container__text">
                <ol type="A">
                  <li>■ Café que requiera una preparación de percolación o extracción con agua y se comercialice como bebida no alcohólica preenvasada.</li>
                  <li>■ Productos que requieran de un modo de preparación a través de adicionar agua u otro líquido natural o transformado.</li>
                  <li>■ Los productos objeto de las siguientes Normas Oficiales Mexicanas, sus modificaciones o las que las sustituyan, conforme a lo establecido en las mismas:
                    <br />
                    <ul>
                      <li> <a href="https://www.dof.gob.mx/normasOficiales/4692/seeco/seeco.htm">NOM-155-SCFI-2012</a>, Leche - Denominaciones, especificaciones fisicoquímicas, información comercial y métodos de prueba, publicada en el Diario Oficial de la Federación el 3 de mayo de 2012.</li>
                      <li> <a href="https://dof.gob.mx/nota_detalle.php?codigo=5549317&fecha=31/01/2019">NOM-181-SCFI/SAGARPA-2018</a>, Yogurt - Denominación, especificaciones fisicoquímicas y microbiológicas, información comercial y métodos de prueba, publicada en el Diario Oficial de la Federación el 31 de enero de 2019.</li>
                    </ul>
                  </li>
                  <li>■ Productos de venta a granel o que se utilicen como materia prima para la elaboración de otros alimentos o bebidas no alcohólicas.</li>
                </ol>
              </p>
            </p>
          </div>
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>

              <dl>

                <dt>Adulteración</dt>
                <dd>Producto cuya naturaleza y composición no corresponda a aquella con que se etiquete, anuncie, expenda, suministre o por no cumplir con las especificaciones descritas en esta Norma Oficial Mexicana, aun cuando haya sufrido un tratamiento que disimule dichas circunstancias o bien, que encubra defectos en su proceso o en la calidad sanitaria de las materias primas utilizadas en él.</dd>

                <dt>Agua de coco</dt>
                <dd>Líquido que se extrae directamente del fruto (Cocos nucifera L.) sin exprimir la pulpa.</dd>

                <dt>Agua de coco concentrada</dt>
                <dd>Es a la que se le ha eliminado físicamente el agua en una cantidad suficiente para elevar el nivel de grados Brix al menos en un 50 % más que el valor Brix establecido para el producto líquido obtenido y que ha sido sometido al tratamiento físico o a las condiciones de almacenamiento adecuadas que aseguren su conservación en el envase.</dd>

              </dl>
            </p>
          </div>
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/173-image-1.svg"
            alt="NOM Logo"
          />
        </div>
        <p className="norm-info__container__text">
          <dl>

            <dt>Agua de coco de concentrado</dt>
            <dd>Agua de coco elaborada a partir de agua de coco concentrada.</dd>

            <dt>Azúcares añadidos</dt>
            <dd>Azúcares libres agregados a los alimentos y a las bebidas no alcohólicas durante la elaboración industrial.</dd>

            <dt>Azúcares libres</dt>
            <dd>Monosacaridos y disacaridos disponibles, añadidos a los alimentos y a las bebidas no alcohólicas por el fabricante, más los azúcares que están presentes naturalmente en miel y jarabes de maíz de alta fructuosa, entre otros jarabes similares.</dd>

            <dt>Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza</dt>
            <dd>Aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje mayor o igual al 10% en su composición final de vegetales o fruta, agua de coco o coco u hortaliza, o la combinación de los mismos, así como uso de extractos naturales, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.</dd>

            <dt>Bebidas saborizadas no alcohólicas</dt>
            <dd>Aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje menor al 10% en su composición final de vegetales o fruta, agua de coco o coco, u hortaliza, o que no contengan ningún porcentaje de los mismos y hagan uso de extractos o saborizantes naturales o artificiales a cualquier vegetal o fruta, agua de coco o coco, u hortaliza, o la combinación de los mismos, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.</dd>

          </dl>
        </p>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/173-image-2.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <dl>

                <dt>Edulcorantes</dt>
                <dd>Sustancias diferentes de los monosacáridos y de los disacáridos, que imparten un sabor dulce a los productos.
                  Fuente: ACUERDO por el que se determinan los aditivos y coadyuvantes en alimentos, bebidas y suplementos alimenticios, su uso y disposiciones sanitarias publicado en el Diario Oficial de la Federación el 16 de julio de 2012 y sus modificaciones.</dd>

                <dt>Frutas cítricas</dt>
                <dd>Frutas de la familia Rutaceae que se dañan con bajas temperaturas y se caracterizan porque tienen aceites y pigmentos en su cáscara.</dd>

                <dt>Frutas sanas</dt>
                <dd>Aquellas libres de enfermedades, heridas, pudriciones, daños producidos por insectos u otras plagas, libres de insectos vivos o muertos o sus larvas.</dd>

                <dt>Frutas o vegetales u hortalizas maduras</dt>
                <dd>Aquellas que han alcanzado el grado de desarrollo adecuado para su consumo (que están en su punto de sazón o madurez).</dd>

                <dt>Grados Brix</dt>
                <dd>Es el porcentaje de sólidos disueltos en un producto.</dd>

                <dt>Jugo</dt>
                <dd>Producto líquido sin fermentar, pero fermentable, obtenido al exprimir vegetales o frutas u hortalizas en buen estado, debidamente maduras y frescas o que se han mantenido en buen estado por procedimientos adecuados, inclusive por tratamientos de superficie aplicados después de la cosecha, clarificado o no, y sometido al tratamiento adecuado que asegura su conservación en el envase.</dd>

                <dt>Jugo de vegetales o frutas u hortalizas concentrado</dt>
                <dd>Es al que se les ha eliminado físicamente el agua en una cantidad suficiente para elevar el nivel de grados Brix al menos en un 50% más que el valor Brix establecido para el producto líquido obtenido al exprimir vegetales o frutas u hortalizas; para el caso de las frutas, deben ser sanas y maduras, y haber sido sometidas
                  al tratamiento físico o a las condiciones de almacenamiento adecuadas que aseguren su conservación en el envase.</dd>

                <dt>Jugo de vegetales o frutas u hortalizas de concentrados</dt>
                <dd>Jugo elaborado a partir de jugo concentrado de vegetales o frutas u hortalizas.</dd>

              </dl>
            </p>
          </div>
        </div> */}
        {/* <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Esta Norma se complementa con las siguientes Normas Oficiales Mexicanas y
          Normas Mexicanas o las que las sustituyan:

          Esta Norma Oficial Mexicana se complementa con las siguientes Normas Oficiales Mexicanas y Normas Mexicanas vigentes, sus modificaciones, o aquellas que las sustituyan:<br />
          <ul>
            <li><a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5263188&fecha=10/08/2012">NOM-002-SCFI-2011</a> Productos preenvasados-Contenido neto-Tolerancias y métodos de verificación, publicada en el Diario Oficial de la Federación el 10 de agosto de 2012.</li>
            <li><a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002">NOM-008-SCFI-2002</a> Sistema General de Unidades de Medida, publicada en el Diario Oficial de la Federación el 27 de noviembre de 2002.</li>
            <li><a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006">NOM-030-SCFI- 2006</a> Información comercial-Declaración de cantidad en la etiqueta-Especificaciones, publicada en el Diario Oficial de la Federación el 6 de noviembre de 2006.</li>
            <li><a href="https://www.cecsatrade.mx/nom-051-scfi-ssa1-2010">NOM-051-SCFI/SSA1-2010</a> Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-Información comercial y sanitaria, publicada en el Diario Oficial de la Federación el 5 de abril de 2010.</li>
            <li><a href="https://dof.gob.mx/nota_detalle.php?codigo=4890075&fecha=26/06/1996">NOM-086-SSA1-1994</a> Bienes y Servicios. Alimentos y bebidas no alcohólicas con modificaciones en su composición. Especificaciones nutrimentales, publicada en el Diario Oficial de la Federación el 26 de junio de 1996.</li>
            <li><a href="https://dof.gob.mx/nota_detalle.php?codigo=4901457&fecha=21/11/1997">NOM-130-SSA1-1995</a> Bienes y servicios. Alimentos envasados en recipientes de cierre hermético y sometidos a tratamiento térmico. Disposiciones y especificaciones sanitarias, publicada en el Diario Oficial de la Federación el 21 de noviembre de 1997.</li>
            <li><a href="https://dof.gob.mx/normasOficiales/4643/salud/salud.htm">NOM-218-SSA1-2011</a> Productos y servicios. Bebidas saborizadas no alcohólicas, sus congelados, productos concentrados para prepararlas y bebidas adicionadas con cafeína. Especificaciones y disposiciones sanitarias. Métodos de prueba, publicada en el Diario Oficial de la Federación el 10 de febrero de 2012.</li>
            <li><a href="https://www.dof.gob.mx/normasOficiales/3980/salud/salud.htm">NOM-251-SSA1-2009</a> Prácticas de higiene para el proceso de alimentos, bebidas o suplementos alimenticios, publicada en el Diario Oficial de la Federación el 1 de marzo de 2010.</li>
            <li><a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5095187&fecha=19/06/2009#gsc.tab=0">NMX-F-103-NORMEX-2009</a> Alimentos-Determinación de grados Brix en alimentos y bebidas, Método de ensayo (prueba). Declaratoria de vigencia publicada en el Diario Oficial de la Federación el 19 de junio de 2009.
              ACUERDO ACUERDO por el que se determinan los aditivos y coadyuvantes en alimentos, bebidas y suplementos alimenticios, su uso y disposiciones sanitarias, publicado en el Diario Oficial de la Federación el 16 de julio de 2012.</li>
          </ul>
        </p> */}
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom142