/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom142: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-189-SSA1/SCFI-2018 - Aseo y limpieza';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-189-ssa1-scfi-2018", "url":
              "https://www.cecsatrade.mx/nom-189-ssa1-scfi-2018", "name":
              "NOM-189-SSA1/SCFI-2018", "description": `La NOM-189-SSA1/SCFI-2018 es una Norma Oficial Mexicana que establece los requisitos sanitarios y comerciales para los productos y servicios de higíene personal, cuidado del hogar y otros productos de uso doméstico garantizando su seguridad. `, "isPartOf": {
                "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
              }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/189.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-189-SSA1/SCFI-2018 es una Norma Oficial Mexicana que establece los requisitos sanitarios y comerciales para los productos y servicios de higíene personal, cuidado del hogar y otros productos de uso doméstico garantizando su seguridad. "
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-189-SSA1/SCFI-2018, PRODUCTOS DE ASEO DE USO DOMÉSTICO</h1>
        <div className='norm-info__header norm-info__header--189'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La norma para <b>productos de aseo de uso dómestico</b> tiene como objeto otorgar la <b>información sanitaria y comercial</b> del etiquetado de estos productos asi como las características requeridas para su envasado, evitando que el consumidor tenga <b>riesgos para la salud</b> y su elección sea la más óptima.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> es aplicable para garantizar un correcto <b>etiquetado de productos de uso dómestico</b> siendo claro y entendible para la protección del consumidor. Esta NOM es de observancia obligatoria en el territorio nacional para quienes se dedican al proceso o importación de productos de aseo.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              La <b>NOM-189-SSA1/SCFI-2018</b> es una <b>Norma Oficial Mexicana</b> que establece los requisitos sanitarios y comerciales para los <b>productos y servicios de higíene personal, cuidado del hogar</b> y otros productos de uso doméstico garantizando su seguridad.
            </p>
          </div>
          <div className='norm-info__image-container__img-container' style={{ margin: '4rem 0' }}>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/nom-189-products.png" alt="nom-189-products" />
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-189-SSA1/SCFI-2018. Información sanitaria y comercial del etiquetado y envasado para productos de aseo de uso dómestico</b> fue publicada el 13 de diciembre de 2018 en el Diario Oficial de la Federación pero entró en vigor un año después el 13 de diciembre de 2019.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://dof.gob.mx/nota_detalle.php?codigo=708514&fecha=01/06/2004#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación</h2>
            <br />
            <p>
              Esta norma es aplicable a todos los <b>productos nacionales</b> y de procedencia extranjera  destinados a comercialización.
              <br />
              <br />
              La <b>NOM-189-SSA1/SCFI-2018</b> establece criterios para la evaluación y el control de la calidad microbiológica de los productos, asegurando que no representen un riesgo para la salud humana.
              <br />
              <div className='norm-info__image-container__aplications-189'>
                <div className='norm-info__image-container__aplications-189__item'>
                  <img loading="lazy" src="/img/jabones.svg" alt="jabones" />
                  <span>Jabones</span>
                </div>
                <div className='norm-info__image-container__aplications-189__item'>
                  <img loading="lazy" src="/img/desinfectantes.svg" alt="desinfectantes" />
                  <span>Desinfectantes</span>
                </div>
                <div className='norm-info__image-container__aplications-189__item'>
                  <img loading="lazy" src="/img/detergentes.svg" alt="detergentes" />
                  <span>Detergentes</span>
                </div>
                <div className='norm-info__image-container__aplications-189__item'>
                  <img loading="lazy" src="/img/limpiadores.svg" alt="limpiadores" />
                  <span>Limpiadores</span>
                </div>
              </div>
              <br />
              Esta norma fue desarrollada con el fin de garantizar la seguridad y eficacia de estos productos para proteger la salud del consumidor y ofrecer <b>información clara en su etiquetado</b>.
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-189 no aplica para</h2>
            <br />
            <p>Estos productos son algunos de los que están excluidos de la norma porque están sujetos a regulaciones más específicas que se ajustan a sus características y riesgos particulares</p>
            <div className='norm-info__image-container__text__data-item-container'>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/189-cosmeticos.svg" alt="cosmeticos" />
                <span>Cosméticos</span>
                <p>Los cosméticos se rigen por otras normativas específicas.</p>
              </div>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/189-industrial.svg" alt="industrial" />
                <span>Productos de limpieza industrial</span>
                <p>La norma no se enfoca en productos de uso industrial, solo al hogar y uso doméstico.</p>
              </div>
            </div>
            <div className='norm-info__image-container__text__data-item-container'>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/189-plaguicidas.svg" alt="plaguicidas" />
                <span>Pesticidas y plaguicidas</span>
                <p>Estos productos están regulados por normativas específicas para sustancias peligrosas y controladas.</p>
              </div>
              <div className='norm-info__image-container__text__data-item norm-info__image-container__text__data-item--twin-vertical'>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/189-veterinaria.svg" alt="C" />
                <span>Productos de uso veterinario</span>
                <p>Los productos destinados al cuidado o tratamiento de animales se rigen por otras normativas.</p>
              </div>
            </div>
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--189' style={{ paddingTop: '30rem', marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <img className='norm-info__image-container__jabon-189' loading="lazy" src="/img/jabon.png" alt="jabon" />
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Coadyuvante o ayudas de proceso</span>
            <p>Componente que facilita el proceso de fabricación y garantiza la estabilidad del producto sin correr riesgos.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Denominación genérica</span>
            <p>Nombre que recibe un grupo de productos que tienen características comunes.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase múltiple o colectivo</span>
            <p>Recipiente o envoltura en el que se encuentran contenidos dos o más productos preenvasados, iguales o no en su composición, destinados para su venta al consumidor en dicha presentación.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase presurizado</span>
            <p>Envasado a presión provisto de una válvula de mando que con un gas propulsor permite proyectar en el aire al producto.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase primario</span>
            <p>Recipiente o envoltura destinada a contener un producto y que entra en contacto directo con el mismo, conservando su integridad física, química y sanitaria. </p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase secundario</span>
            <p>Contiene al envase primario de manera individual, y no está en contacto directo con el producto.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Etiqueta</span>
            <p>Marbete, rótulo, inscripción, marca, imagen gráfica u otra forma descriptiva que se haya escrito, impreso, marcado, en relieve o en hueco, grabado o adherido al empaque o envase del producto</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Ingrediente</span>
            <p>Sustancia que forma parte de la composición o fórmula del producto terminado.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Irritación</span>
            <p>Efecto inflamatorio reversible en el tejido vivo por una acción química en el sitio de contacto.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Leyenda precautoria</span>
            <p>Texto o representación gráfica que informe y, en su caso, prevenga al consumidor, sobre los posibles daños a la salud.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Lote</span>
            <p>Cantidad de un producto elaborado en un mismo ciclo, integrado por unidades homogéneas.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Muestra de obsequio</span>
            <p>Ejemplar que se utiliza con el propósito de dar a conocer el producto mediante su distribución gratuita al público en general.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto a granel</span>
            <p>Aquel a colocarse en un envase primario para la venta al consumidor final, cuyo contenido puede ser variable, debiéndose pesar, contar o medir en presencia del consumidor por no encontrarse envasado al momento de su venta.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto corrosivo</span>
            <p>Aquel que en estado sólido, líquido o gaseoso causa destrucción o alteraciones irreversibles en el tejido vivo por acción química en el sitio de contacto.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos de aseo</span>
            <p>Sustancia o mezclas de sustancias que se emplean de forma directa o indirecta destinadas a: la limpieza, lavado e higiene de objetos, superficies y fibras textiles, desprenden o eliminar la suciedad y las manchas; proporcionar un determinado aroma o eliminar malos olores del ambiente.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos de aseo de uso dómestico</span>
            <p>Destinada a los consumidores que los usan o aplican en las diferentes áreas del hogar o instalaciones similares, tales como: oficinas, escuelas y en hospitales excepto las áreas blancas (por ejemplo: quirófano, áreas de recuperación, enfermería), entre otros.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos especiales para textiles</span>
            <p>Productos de aseo de uso doméstico elaborados con una sustancia o mezcla de sustancias que facilitan el aseo y mantenimiento de fibras textiles al modificar o acondicionar la textura o cualquier otra característica de las telas. En esta categoría se encuentran almidones o aprestos, blanqueadores, productos para planchado, prelavadores, desmanchadores y suavizantes.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto inflamable</span>
            <p>Aquellos que tiene un punto de inflamación menor o igual a 37,8 °C, prende fácilmente y se quema rápidamente, generalmente de forma violenta.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos multifuncionales</span>
            <p>Aquellos de aseo de uso doméstico que debido a su formulación pueden tener varias de las funciones tecnológicas descritas en los incisos 3.21, 3.24, 3.25, 3.26 y 3.27, de esta Norma, así como alguna otra función tecnológica diferente de las anteriores.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos para desobstruir conductos sanitarios</span>
            <p>Aquellos de aseo de uso doméstico elaborados con una sustancia o mezcla de sustancias cuya acción química o biológica remueve materia presente en los conductos sanitarios de las aguas residuales y pluviales que pudieran obstruirlas. </p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos aromatizantes y otros de control de olores</span>
            <p>Aquellos de aseo de uso doméstico elaborados con una sustancia o mezcla de sustancias usados con el objeto de impartir un aroma al ambiente o prevenir o enmascarar, neutralizar o eliminar malos olores en cualquier espacio o superficie. En esta categoría entran aromatizantes, desodorantes entre otros. </p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos para la limpieza</span>
            <p>Aquellos de aseo de uso doméstico elaborados con una sustancia o mezcla de sustancias que eliminan o disminuyen la suciedad orgánica o inorgánica, o que disminuyan el desarrollo de microorganismos de las superficies donde se aplica. En esta categoría se encuentran detergentes, jabones de lavandería, desengrasantes, limpiadores, desinfectantes, desmanchadores y removedores.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos para protección o acabado de lustros</span>
            <p>Aquellos de aseo de uso doméstico elaborados con una sustancia o mezcla de sustancias de origen natural o sintética que proporcionan a una superficie un acabado protector o reparador, estético, brillante o una combinación de ellos. En esta categoría se encuentran ceras, lustradores, abrillantadores y selladores.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Producto tóxico</span>
            <p>Aquellos que en estado sólido, líquido o gaseoso y que, en función de la concentración, vía de administración y tiempo de exposición puede causar trastornos estructurales o funcionales que provoquen daños a la salud o la muerte.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Responsable del producto</span>
            <p>Persona física o moral que importe, fabrique un producto o que haya ordenado su elaboración total o parcial a un tercero, distribuya o comercialice; y en el caso de producto a granel quien envase a éste al momento de su venta.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Superficie de información</span>
            <p>Área del envase primario o secundario, distinta a la superficie principal de exhibición.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Superficie principal de exhibición</span>
            <p>Área donde se encuentra la denominación genérica o, en su caso, especifica y la marca comercial del producto.</p>
          </div>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>
        <div className='norm-info__image-container norm-info__image-container__text norm-info__image-container__text--center'>
          <h2>REQUERIMIENTOS DE LA NOM-189-SSA1/SCFI-2018</h2>
          <div className='norm-info__image-container__requeriment-189-container'>
            <div className='norm-info__image-container__requeriment-189'>
              <img src="/img/nom-189-requirement-1.svg" alt="nom-189-requirement-1" />
              <p>Si un producto se comercializa en un envase múltiple o colectivo, este debe incluir al menos la marca, el nombre y la cantidad, a menos que el envase permita leer las etiquetas individuales.</p>
            </div>
            <div className='norm-info__image-container__requeriment-189'>
              <img src="/img/nom-189-requirement-2.svg" alt="nom-189-requirement-2" />
              <p>Cada envase individual o colectivo debe llevar la leyenda "No etiquetado para su venta individual", además de las leyendas precautorias y, si aplica, recomendaciones de seguridad.</p>
            </div>
            <div className='norm-info__image-container__requeriment-189'>
              <img src="/img/nom-189-requirement-3.svg" alt="nom-189-requirement-3" />
              <p>Las muestras gratuitas deben etiquetarse con al menos el nombre del producto, datos del responsable, instrucciones de uso, leyendas precautorias, contenido neto y lote.</p>
            </div>
            <div className='norm-info__image-container__requeriment-189'>
              <img src="/img/nom-189-requirement-4.svg" alt="nom-189-requirement-4" />
              <p>Las etiquetas deben ser diseñadas para que la información permanezca visible durante todo el uso del producto, incluso en productos a granel vendidos al consumidor.</p>
            </div>
          </div>
        </div>
        <BenefictsAndWhoDifferent title={'CERTIFICA TUS PRODUCTOS'} text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'} />
        <References
          items={[
            (
              <li>
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5263188&fecha=10/08/2012#gsc.tab=0" target='_blank'>NOM-002-SCFI-2011</a>, Productos preenvasados - Contenido neto - Tolerancias y métodos de verificación.
              </li>
            ),
            (
              <li>
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002" target='_blank'>NOM-008-SCFI-2002</a>, Sistema general de unidades de medida.
              </li>
            ),
            (
              <li>
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0" target='_blank'>NOM-030-SCFI-2006</a>, Información comercial - Declaración de cantidad en la etiqueta - Especificaciones
              </li>
            )
          ]}
        />
        {/* <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/187-image-1.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <h2
                className="norm-info__container__sub-title"
                id="definiciones"
              >Definiciones</h2>
              <dl>

                <dt>Coadyuvante o ayudas de procesos</dt>
                <dd>Componente complementario que confiere propiedades no relacionadas
                  con la acción principal del producto, agregados para facilitar el
                  proceso de fabricación y garantizar la estabilidad del producto, sin
                  tener efecto o acción sobre las propiedades funcionales del mismo y
                  que no confieren ningún riesgo a la salud o al medio ambiente.</dd>

                <dt>Consumidor</dt>
                <dd>Persona física o moral que adquiere como destinatario final los
                  productos de aseo de uso doméstico.Denominación específica, al nombre
                  particular que recibe un producto.</dd>

                <dt>Denominación genérica</dt>
                <dd>Nombre que recibe un grupo de productos, que en función de su uso
                  tienen características comunes y que representa cada uno de los
                  distintos tipos o clases en que se pueden ordenar.</dd>

                <dt>Envase múltiple o colectivo</dt>
                <dd>Recipiente o envoltura en el que se encuentran contenidos dos o más
                  productos preenvasados, iguales o no en su composición, destinados
                  para su venta al consumidor en dicha presentación.</dd>

              </dl>
            </p>
          </div>
        </div>
        <div className="norm-info__container__image-container">
          <div>
            <dl>

              <dt>Envase presurizado</dt>
              <dd>Envasado a presión provisto de una válvula de mando que con un gas
                propulsor permite proyectar en el aire al producto.</dd>

              <dt>Envase primario</dt>
              <dd>Recipiente o envoltura destinada a contener un producto y que
                entra en contacto directo con el mismo, conservando su integridad
                física, química y sanitaria. El envase primario puede estar
                contenido en un envase secundario.</dd>

              <dt>Envase resistente a niños</dt>
              <dd>Diseñado o construido de tal manera que presente dificultad para
                ser abierto por niñas y niños menores de cinco años.</dd>

              <dt>Envase secundario</dt>
              <dd>Contiene al envase primario de manera individual, y no está en
                contacto directo con el producto.</dd>

              <dt>Etiqueta</dt>
              <dd>Marbete, rótulo, inscripción, marca, imagen gráfica u otra forma
                descriptiva que se haya escrito, impreso, estarcido, marcado, en
                relieve o en hueco, grabado, adherido, precintado o anexado al
                empaque o envase del producto.</dd>

              <dt>Ingrediente</dt>
              <dd>Sustancia que forma parte de la composición o fórmula del producto
                terminado.</dd>

              <dt>Irritación</dt>
              <dd>Efecto inflamatorio reversible en el tejido vivo por una acción
                química en el sitio de contacto.</dd>

              <dt>Leyenda precautoria</dt>
              <dd>Texto o representación gráfica que informe y, en su caso, prevenga
                al consumidor, sobre los posibles daños a la salud que ocasione la
                presencia de un ingrediente específico o el mal uso o aplicación del
                producto.</dd>

            </dl>
          </div>
        </div>
        <p className="norm-info__container__text">
          <dl>

            <dt>Lote</dt>
            <dd>Cantidad de un producto elaborado en un mismo ciclo, integrado por
              unidades homogéneas.</dd>

            <dt>Muestra de obsequio</dt>
            <dd>Ejemplar que se utiliza con el propósito de dar a conocer el
              producto mediante su distribución gratuita al público en general.</dd>

            <dt>Proceso</dt>
            <dd>Conjunto de actividades relativas a la obtención, elaboración,
              fabricación, preparación, conservación, mezclado, acondicionamiento,
              envasado, manipulación, transporte, distribución, almacenamiento y
              expendio o suministro al consumidor de productos.</dd>

            <dt>Producto a granel</dt>
            <dd>Aquel a colocarse en un envase primario para la venta al consumidor
              final, cuyo contenido puede ser variable, debiéndose pesar, contar o
              medir en presencia del consumidor por no encontrarse envasado al
              momento de su venta. Mismo que se obtendrá de un contenedor
              debidamente identificado.</dd>

            <dt>Producto corrosivo</dt>
            <dd>Aquel que en estado sólido, líquido o gaseoso causa destrucción o
              alteraciones irreversibles en el tejido vivo por acción química en el
              sitio de contacto.</dd>

            <dt>Productos de aseo</dt>
            <dd>Sustancia o mezclas de sustancias que se emplean de forma directa o
              indirecta, independientemente de su estado físico, destinadas a: la
              limpieza, lavado e higiene de objetos, superficies y fibras textiles y
              que tienen por objeto desprender o eliminar la suciedad y las manchas;
              proporcionar un determinado aroma o eliminar malos olores del
              ambiente; impartir un acabado lustroso a objetos y superficies,
              modificar y acondicionar la textura o cualquier otra característica de
              las telas; desobstruir los ductos sanitarios de las aguas residuales y
              pluviales y los demás de naturaleza análoga que determine la
              Secretaría.</dd>

            <dt>Producto de aseo de uso doméstico</dt>
            <dd>Destinada a los consumidores que los usan o aplican en las
              diferentes áreas del hogar o instalaciones similares, tales como:
              oficinas, escuelas y en hospitales excepto las áreas blancas (por
              ejemplo: quirófano, áreas de recuperación, enfermería), entre otros.</dd>

            <dt>Productos especiales para textiles</dt>
            <dd>Productos de aseo de uso doméstico elaborados con una sustancia o
              mezcla de sustancias que facilitan el aseo y mantenimiento de fibras
              textiles al modificar o acondicionar la textura o cualquier otra
              característica de las telas. En esta categoría se encuentran, de
              manera enunciativa mas no limitativa, los siguientes productos:
              almidones o aprestos, blanqueadores, productos para planchado,
              prelavadores, desmanchadores y suavizantes.</dd>

          </dl>
        </p>
        <div className="norm-info__container__image-container">
          <img
            loading="lazy"
            className="norm-info__container__image-container__image"
            src="/img/189-image-2.svg"
            alt="NOM Logo"
          />
          <div>
            <p className="norm-info__container__text">
              <dl>

                <dt>Producto inflamable</dt>
                <dd>Aquellos que tiene un punto de inflamación menor o igual a 37,8
                  °C, prende fácilmente y se quema rápidamente, generalmente de
                  forma violenta.</dd>

                <dt>Productos multifuncionales</dt>
                <dd>Aquellos de aseo de uso doméstico que debido a su formulación
                  pueden tener varias de las funciones tecnológicas descritas en los
                  incisos 3.21, 3.24, 3.25, 3.26 y 3.27, de esta Norma, así como
                  alguna otra función tecnológica diferente de las anteriores.</dd>

                <dt>Productos para desobstruir conductos sanitarios</dt>
                <dd>Aquellos de aseo de uso doméstico elaborados con una sustancia o
                  mezcla de sustancias cuya acción química o biológica remueve
                  materia presente en los conductos sanitarios de las aguas
                  residuales y pluviales que pudieran obstruirlas. En esta categoría
                  se encuentran, de manera enunciativa mas no limitativa, los
                  destapacaños.</dd>

                <dt>Productos aromatizantes y otros de control de olores</dt>
                <dd>Aquellos de aseo de uso doméstico elaborados con una sustancia o
                  mezcla de sustancias usados con el objeto de impartir un aroma al
                  ambiente o prevenir o enmascarar, neutralizar o eliminar malos
                  olores en cualquier espacio o superficie. En esta categoría se
                  encuentran, de manera enunciativa mas no limitativa, los
                  siguientes productos: aromatizantes, desodorantes, enmascarantes y
                  los eliminadores, neutralizadores o controladores de malos olores
                  del ambiente.</dd>

                <dt>Productos para la limpieza</dt>
                <dd>Aquellos de aseo de uso doméstico elaborados con una sustancia o
                  mezcla de sustancias que eliminan o disminuyen la suciedad
                  orgánica o inorgánica, o que disminuyan el desarrollo de
                  microorganismos de las superficies donde se aplica. En esta
                  categoría se encuentran, de manera enunciativa mas no limitativa,
                  los siguientes productos: detergentes, jabones de lavandería,
                  desengrasantes, limpiadores, desinfectantes, desmanchadores y
                  removedores.</dd>

              </dl>
            </p>
          </div>
        </div>
        <p className="norm-info__container__text">
          <dl>

            <dt>Productos para protección o acabado lustros</dt>
            <dd>Aquellos de aseo de uso doméstico elaborados con una sustancia o
              mezcla de sustancias de origen natural o sintética que proporcionan a
              una superficie un acabado protector o reparador, estético, brillante o
              una combinación de ellos. En esta categoría se encuentran, de manera
              enunciativa mas no limitativa, los siguientes productos: ceras,
              lustradores, abrillantadores y selladores.</dd>

            <dt>Productos preenvasados</dt>
            <dd>Productos terminados que cuando son colocados en un envase de
              cualquier naturaleza, no se encuentra presente el consumidor y la
              cantidad de producto contenido en él, no puede ser alterada, a menos
              que el envase sea abierto o modificado perceptiblemente.</dd>

            <dt>Producto tóxico</dt>
            <dd>Aquellos que en estado sólido, líquido o gaseoso y que, en función
              de la concentración, vía de administración y tiempo de exposición
              puede causar trastornos estructurales o funcionales que provoquen
              daños a la salud o la muerte.</dd>

            <dt>Responsable del producto</dt>
            <dd>Persona física o moral que importe, fabrique un producto o que haya
              ordenado su elaboración total o parcial a un tercero, distribuya o
              comercialice; y en el caso de producto a granel quien envase a éste al
              momento de su venta.</dd>

            <dt>Superficie de información</dt>
            <dd>Área del envase primario o secundario, distinta a la superficie
              principal de exhibición.</dd>

            <dt>Superficie principal de exhibición</dt>
            <dd>Área donde se encuentra la denominación genérica o, en su caso,
              especifica y la marca comercial del producto.</dd>

          </dl>
        </p> */}
        <NormInfo
          DOFDate='13/12/2018'
          VigorDate='13/12/2019'
          responsible='SECRETARIA DE SALUD y SECRETARIA DE ECONOMIA'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Cuáles son las características que debe cumplir el etiquetado de la NOM-189-SSA1/SCFI-2018?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Las características que debe tener son muy especificas en cuanto al etiquetado debido que algunos productos de limpieza tienen componentes químicos, se debe clasificar de acuerdo a con base a su función tecnológica, debe contener la denominación genérica y específica, identificación del responsable del producto,  declaración de la lista de ingredientes, leyenda precautoria, instrucciones de uso, declaración de lote. país de origen, como sus generalidades. Además, todas las etiquetas deben ser diseñadas, elaboradas y fijadas de tal forma que la información contenida en las mismas permanezca disponible durante el uso normal del producto, inclusive cuando se trate de productos de venta a granel que se expenden al consumidor.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  Tipos de productos que aplican en la NOM-189-SSA1/SCFI-2018
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Los productos que se encuentran son los jabones, detergentes, limpiadores, blanqueadores, almidones para uso externo, desmanchadores, desinfectantes, desodorantes y aromatizantes ambientales, y los demás de naturaleza análoga que determine la Secretaría de Salud.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Cómo cumplir con el etiquetado de  producto de la NOM-189-SSA1/SCFI-2018?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Para realizar el cumplimiento de esta norma te invitamos a acercarte a una unidad de inspección acreditada, con CECSA te brindamos el apoyo de revisar la información comercial de tu mercancía  para proporcionarte el etiquetado correcto  con base a esta normativa  y así al ser verificado el producto, se podrán <b>imprimir las etiquetas y reetiquetar</b> en el caso de que lleve algún sello o leyenda emitiendo como resultado final  un dictamen de cumplimiento para que tu mercancía teniendo en cuenta las fechas que sean indicadas  para no tener problema alguno.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Quién emite la NOM-189-SSA1/SCFI-2018?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La Secretaría de Salud
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  Conoce las consecuencias y sanciones al no cumplir con los requerimientos de la NOM-189-SSA1/SCFI-2018
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo a la Ley  Federal de la Metrología y Normalización, en el articulo 112, nos dice que si un producto no cumple con la NOM puede generar multas, clausura temporal o definitiva, que podrá ser parcial o total, arresto hasta por treinta y seis horas, suspensión o revocación de la autorización, aprobación, o registro según corresponda y suspensión o cancelación del documento donde consten los resultados de la evaluación de la conformidad, así como de la autorización del uso de contraseñas y marcas registradas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Quiénes deben cumplir con esta NOM-189-SSA1/SCFI-2018?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Esta norma es de carácter obligatorio en territorio nacional para las personas físicas y morales que se dedican al proceso o importación de productos de aseo de uso doméstico.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Cómo saber si mi producto entra dentro de los requisitos de la NOM-189-SSA1/SCFI-2018?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La NOM-189-SSA1/SCFI-2018 para productos de aseo de uso doméstico, establece criterios sobre los ingredientes permitidos, prohibidos o restringidos en productos . Se debe verificar si los ingredientes del producto están dentro de las listas aceptadas por la norma, como que el etiquetado este correcto de acuerdo a lo establecido.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Cómo importar un producto con la NOM-189-SSA1/SCFI-2018?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Para importar a México un producto es necesario considerar un proceso detallado para asegurar que el producto cumpla con las regulaciones sanitarias y comerciales establecidas por esta norma. En lo cual de necesita identificar el producto a importar y identificar que este dentro de las regulaciones de la norma, también antes de importar, debes obtener un Certificado de Conformidad emitido por una Unidad de Verificación Acreditada (UVA), tener bien el etiquetado de acuerdo a lo establecido en la NOM, debes tener una correcta clasificación arancelaria y un pedimento de importación. Para después en aduana poder realizar el despacho de manera optima y ágil, también es importante mencionar el pago de impuestos al comercio exterior, para después cuando se hayan liberado las mercancías ya puedas comercializarlos en territorio nacional.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  ¿Qué químicos pueden entrar en mis productos?
                </button>
              </div>
              <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Fosfatos, blanqueadores base oxígeno, blanqueadores base cloro, sulfatos, ceras, entre otros.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading10">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                  ¿Qué leyendas precautorias debe tener el producto de acuerdo a la NOM- 189?
                </button>
              </div>
              <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo al tipo de producto, el fabricante debe incluir en la etiqueta, las leyendas precautorias y de considerarse necesario, las recomendaciones de seguridad.
                  <br /><br />En los productos tóxicos debe decir:
                  <br /> &#160;&#160;&#160;&#8226; Que contiene sustancias tóxicas cuya ingestión, inhalación o contacto directo inmediato o prolongado, según corresponda, pueden originar graves daños a la salud.
                  <br /> &#160;&#160;&#160;&#8226; Que no se deje al alcance de los niños.
                  <br /> &#160;&#160;&#160;&#8226; Se deben incluir imágenes para identificar a un producto con propiedades tóxicas.
                  <br /><br />En los productos corrosivos debe contener:
                  <br /> &#160;&#160;&#160;&#8226; Lea la etiqueta antes de usar este producto.
                  <br /> &#160;&#160;&#160;&#8226; Que no reutilice el envase y que una vez terminado se deseche el mismo.
                  <br /> &#160;&#160;&#160;&#8226; Que para su empleo se utilicen guantes.
                  <br />Entre otros.
                </div>
              </div>
            </li>
          </ul>
        </div>
        {/* <p className="norm-info__container__text">
          <h2
            className="norm-info__container__sub-title"
            id="referencias"
          >Referencias</h2>
          Para la aplicación correcta de esta Norma es necesario consultar las
          siguientes Normas Oficiales Mexicanas o las que las sustituyan:
          <br />
          <ul>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=5263188&fecha=10/08/2012"
            >NOM-002-SCFI-2011</a>, Productos preenvasados - Contenido neto -
              Tolerancias y métodos de verificación.</li>
            <li><a
              href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002"
            >NOM-008-SCFI-2002</a>, Sistema general de unidades de medida.</li>
            <li><a
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006"
            >NOM-030-SCFI-2006</a>, Información comercial - Declaración de cantidad
              en la etiqueta - Especificaciones.</li>
          </ul>
        </p> */}
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom142