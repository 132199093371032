/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { Button, JsonLd, } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom051: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'Quejas y apelaciones';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/complaints_and_appeals", "url":
              "https://www.cecsatrade.mx/complaints_and_appeals", "name":
              "NOM-051-SCFI/SSA1-2010", "description": `CECSA ENTERPRISE SOLUTIONS SC pone a disposición de sus clientes y partes interesadas el siguiente procedimiento para Quejas y Apelaciones de acuerdo al Sistema de Gestión de Calidad y a la NMX-EC-17020-IMNC-2014, por lo que te pedimos descargar el procedimiento y llenar el formato correspondiente. Una vez terminados, enviar al correo contacto@cecsatrade.mx para su posterior seguimiento por parte de CECSA
              CECSA cuenta con un periodo de 10 días hábiles para enviar una respuesta atendiendo a la parte interesada.`, "isPartOf": {
              "@id":
                "https://www.cecsatrade.mx/#/schema/WebSite/1"
            }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/051.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Quejas y apelaciones: CECSA ENTERPRISE SOLUTIONS SC pone a disposición de sus clientes y partes interesadas el siguiente procedimiento para Quejas y Apelaciones de acuerdo al Sistema de Gestión de Calidad y a la NMX-EC-17020-IMNC-2014, por lo que te pedimos descargar el procedimiento y llenar el formato correspondiente. Una vez terminados, enviar al correo contacto@cecsatrade.mx para su posterior seguimiento por parte de CECSA
              CECSA cuenta con un periodo de 10 días hábiles para enviar una respuesta atendiendo a la parte interesada."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>Quejas y Apelaciones</h1>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <br />
            <p>
              CECSA ENTERPRISE SOLUTIONS SC pone a disposición de sus clientes y partes interesadas el siguiente procedimiento para Quejas y Apelaciones de acuerdo al Sistema de Gestión de Calidad y a la NMX-EC-17020-IMNC-2014, por lo que te pedimos descargar el procedimiento y llenar el formato correspondiente. Una vez terminados, enviar al correo contacto@cecsatrade.mx para su posterior seguimiento por parte de CECSA
              CECSA cuenta con un periodo de 10 días hábiles para enviar una respuesta atendiendo a la parte interesada.
            </p>
          </div>
        </div>
        <div className='documments-container' style={{ paddingBottom: '20rem' }}>
          <h2>Documentos relacionados</h2>
          <div className="documments-container__document">
            <h3 className="documments-container__document__title">F7-12 Formato de Quejas y Apelaciones</h3>
            <img loading="lazy" className="documments-container__document__image" src="/img/download.svg" alt="download" />
            <Button
              text={'Descargar'}
              onClick={undefined}
              type={'black'}
              anchor={true}
              href='https://s3.amazonaws.com/images.cecsa.com/documents/F7-12+Formato+de+Quejas+y+Apelaciones.xls'
              target='_blank'
              icon='download-arrow.svg'
              className='documments-container__document__button'
            />
          </div>
          <div className="documments-container__document">
            <h3 className="documments-container__document__title">P7-06 Procedimiento de quejas y apelaciones</h3>
            <img loading="lazy" className="documments-container__document__image" src="/img/pdf.svg" alt="pdf" />
            <Button
              text={'Descargar'}
              onClick={undefined}
              type={'black'}
              anchor={true}
              href='https://s3.amazonaws.com/images.cecsa.com/documents/P7-06+Procedimiento+de+quejas+y+apelaciones.pdf'
              target='_blank'
              icon='download-arrow.svg'
              className='documments-container__document__button'
            />
          </div>
        </div>
      </section >
    </>
  )
}

export default Nom051