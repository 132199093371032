import { ReactElement } from 'react';
import './styles.scss';

interface Question {
  question: string
  response: ReactElement
}

interface Props {
  questions: Question[]
}

const QuestionsService: React.FC<Props> = (props) => {
  const {
    questions
  } = props;

  return (
    <section className='norm-info__questions questions-services'>
      <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
      <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
        {
          questions.map((question: Question, index: number) => (
            <li className="accordion-item">
              <div id={`heading${index + 1}`}>
                <button className={`accordion-button norm-info__container__accordion-button ${index !== 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index + 1}`} aria-expanded={index === 0} aria-controls={`collapse${index + 1}`}>
                  {question.question}
                </button>
              </div>
              <div id={`collapse${index + 1}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index + 1}`} data-bs-parent="#accordion">
                <div className="accordion-body">
                  {question.response}
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    </section>
  )
}

export default QuestionsService