
import { Helmet } from 'react-helmet';

interface Props {
  data: any
}

const JsonLd: React.FC<Props> = (props) => {
  const {
    data
  } = props;

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    </Helmet>
  )
}

export default JsonLd