import { useId } from 'react';
import './styles.scss';

interface Props {
  text: string
  icon?: string
  iconPosition?: 'left' | 'rigth'
  iconClassName?: string
  className?: string
  onClick: any
  size?: 'small' | 'big'
  type: 'ligth' | 'black' | 'green'
  anchor?: boolean
  href?: string
  target?: '_blank' | '_parent' | '_self' | '_top'
  id?: string
}

const Button: React.FC<Props> = (props) => {
  const {
    text,
    icon = '',
    iconPosition = 'left',
    iconClassName = '',
    className = '',
    onClick,
    size,
    type,
    anchor = false,
    href = '',
    target = '_blank',
    // eslint-disable-next-line react-hooks/rules-of-hooks
    id = useId()
  } = props;

  if (anchor) {
    return (
      <a className={`${className} button button--${size} button--${type}`} href={href} target={target} id={id}>
        {
          iconPosition === 'left' && icon && (
            <img className={`button__icon ${iconClassName}`} src={`/img/${icon}`} alt={`${icon} Logo`} />
          )
        }
        {text}
        {
          iconPosition === 'rigth' && icon && (
            <img className={`button__icon ${iconClassName}`} src={`/img/${icon}`} alt={`${icon} Logo`} />
          )
        }
      </a>
    )
  }

  return (
    <button className={`${className} button button--${size} button--${type}`} onClick={() => onClick()}>
      {
        iconPosition === 'left' && icon && (
          <img className={`button__icon ${iconClassName}`} src={`/img/${icon}`} alt={`${icon} Logo`} />
        )
      }
      {text}
      {
        iconPosition === 'rigth' && icon && (
          <img className={`button__icon ${iconClassName}`} src={`/img/${icon}`} alt={`${icon} Logo`} />
        )
      }
    </button>
  )
}

export default Button