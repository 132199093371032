
import { ReactElement } from 'react';
import './styles.scss';

interface Props {
  items: ReactElement[]
}

const References: React.FC<Props> = (props) => {
  const {
    items
  } = props;

  return (
    <div className='references'>
      <h2>REFERENCIAS</h2>
      <p>Esta norma se conplementa con las siguientes Normas Oficiales Mexicanas</p>
      <ul>
        {
          items.map((item => (
            item
          )))
        }
      </ul>
    </div>
  )
}

export default References