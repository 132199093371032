import './styles.scss'
import { useEffect } from 'react';

export const Newsletter = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if ((window as any).hbspt) {
        // @TS-ignore
        (window as any).hbspt.forms.create({
          region: "na1",
          portalId: "43670312",
          formId: "22560268-5cad-4e4c-870b-f988be6a0cc2",
          target: '#hubspotFormContainer'
        })
      }
    });
  }, []);

  return (
    <>
      <section className='newsletter'>
        <div className='newsletter__image-container'>
          <img className='newsletter__image-container__image' src="/img/newsletter.svg" alt="newsletter" />
        </div>
        <div className='newsletter__text-container'>
          <h2 className='newsletter__text-container__title'>Suscribete a nuestro boletin mensual</h2>
          <p className='newsletter__text-container__text'>Somos un grupo de personas que puede apoyarte a vincularte con las principales agencias regulatorias y de esa manera reducir y facilitar los requisitos para que puedas comercializar en México.</p>
          <div className='newsletter__text-container__form' id="hubspotFormContainer"></div>
        </div>
      </section>
    </>
  )
}

export default Newsletter