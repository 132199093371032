import { useNavigate } from 'react-router-dom';
import './styles.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState, useRef, useEffect } from 'react';

interface Props {
  title: string
  text: string
  paddingTop?: string
}

const BenefictsAndWhoDifferent: React.FC<Props> = (props) => {
  const {
    title,
    text,
    paddingTop = ''
  } = props;
  const navigate = useNavigate();
  const [count1, setCount1] = useState<number>(0);
  const [count2, setCount2] = useState<number>(0);
  const [count3, setCount3] = useState<number>(0);
  const counterRef1 = useRef(null);
  const counterRef2 = useRef(null);
  const counterRef3 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let startTime: number | null = null;

          const animate = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const progress = currentTime - startTime;
            const increment = Math.min(Math.floor(progress / 2000 * (50 - 0)), 50);

            setCount1(increment);

            if (increment < 50) {
              requestAnimationFrame(animate);
            }
          };

          const animate2 = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const progress = currentTime - startTime;
            const increment = Math.min(Math.floor(progress / 1000 * (10 - 0)), 10);

            setCount2(increment);

            if (increment < 10) {
              requestAnimationFrame(animate2);
            }
          };

          const animate3 = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const progress = currentTime - startTime;
            const increment = Math.min(Math.floor(progress / 3000 * (100 - 0)), 100);

            setCount3(increment);

            if (increment < 100) {
              requestAnimationFrame(animate3);
            }
          };

          requestAnimationFrame(animate);
          requestAnimationFrame(animate2);
          requestAnimationFrame(animate3);
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });

    if (counterRef1.current) {
      observer.observe(counterRef1.current);
    }

    if (counterRef2.current) {
      observer.observe(counterRef2.current);
    }

    if (counterRef3.current) {
      observer.observe(counterRef3.current);
    }

    return () => {
      if (counterRef1.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(counterRef1.current);
      }
      if (counterRef2.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(counterRef2.current);
      }
      if (counterRef3.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(counterRef3.current);
      }
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className='benefits' style={{ paddingTop: paddingTop }}>
        <h3 id="benefits">BENEFICIOS</h3>
        <div className='benefits__container'>
          <div className='benefits__container__item'>
            <img className='benefits__container__item__img' loading="lazy" src="/img/benefits-1.svg" alt="benefits 1" />
            <span><b>Evita multas</b> y <b>sanciones</b> con el correcto cumplimiento</span>
          </div>
          <div className='benefits__container__item'>
            <img className='benefits__container__item__img' loading="lazy" src="/img/benefits-2.svg" alt="benefits 2" />
            <span>Garantiza la <b>confianza</b> a los consumidores</span>
          </div>
          <div className='benefits__container__item'>
            <img className='benefits__container__item__img' loading="lazy" src="/img/benefits-3.svg" alt="benefits 3" />
            <span><b>Reduce</b> los costos operativos</span>
          </div>
          <div className='benefits__container__item'>
            <img className='benefits__container__item__img' loading="lazy" src="/img/benefits-4.svg" alt="benefits 4" />
            <span>Evita caer en sanciones por el <b>mal etiquetado</b></span>
          </div>
          <div className='benefits__container__item'>
            <img className='benefits__container__item__img' loading="lazy" src="/img/benefits-5.svg" alt="benefits 5" />
            <span><b>Comercializa</b> fácilmente tus productos en el país</span>
          </div>
          <div className='benefits__container__item'>
            <img className='benefits__container__item__img' loading="lazy" src="/img/benefits-6.svg" alt="benefits 6" />
            <span>Brinda <b>calidad</b> al consumidor</span>
          </div>
        </div>
        {paddingTop}
        <div className='benefits__container-different'>
          <h3>¿Qué nos hace diferente?</h3>
          <div className='main__section-2__container'>
            <div id="element1" className='main__section-2__element'>
              <img src="/img/section1.svg" alt="trámites agiles" className='main__section-2__element__img' />
              <div className='main__section-2__element__text'>
                <span className='main__section-2__element__text__number' ref={counterRef1}>+{count1}%</span>
                <span>Trámites Ágiles</span>
              </div>
            </div>
            <div id="element2" className='main__section-2__element'>
              <img src="/img/section2.svg" alt="años de experiencia" className='main__section-2__element__img' />
              <div className='main__section-2__element__text'>
                <span className='main__section-2__element__text__number' ref={counterRef2}>+{count2}</span>
                <span>Años de experiencia</span>
              </div>
            </div>
            <div id="element3" className='main__section-2__element'>
              <img src="/img/section3.svg" alt="empresas atendidas" className='main__section-2__element__img' />
              <div className='main__section-2__element__text'>
                <span className='main__section-2__element__text__number' ref={counterRef3}>+{count3}</span>
                <span>Empresas atendidas</span>
              </div>
            </div>
          </div>
          <h4>
            ESTAMOS PRESENTES EN
            <img src="/img/map.svg" alt="mapa" />
          </h4>
          <div className='benefits__container-different__locations'>
            <div className='benefits__container-different__locations__item' >
              <img src="/img/map-dot.svg" alt="map location" />
              <span>Arandas</span>
            </div>
            <div className='benefits__container-different__locations__item' >
              <img src="/img/map-dot.svg" alt="map location" />
              <span>Guadalajara</span>
            </div>
            <div className='benefits__container-different__locations__item' >
              <img src="/img/map-dot.svg" alt="map location" />
              <span>CDMX</span>
            </div>
            <div className='benefits__container-different__locations__item' >
              <img src="/img/map-dot.svg" alt="map location" />
              <span>Tijuana</span>
            </div>
          </div>
        </div>
      </div>
      <div className='benefits-organisms'>
        <h2>ORGANISMOS INVOLUCRADOS</h2>
        <div className='benefits-organisms__conteiner'>
          <div className='benefits-organisms__conteiner__item'>
            <img src="/img/ema.svg" alt="map location" />
          </div>
          <div className='benefits-organisms__conteiner__item'>
            <img src="/img/dgn.svg" alt="map location" />
          </div>
          <div className='benefits-organisms__conteiner__item'>
            <img src="/img/SE.svg" alt="map location" />
          </div>
          <div className='benefits-organisms__conteiner__item'>
            <img src="/img/PROFECO.svg" alt="map location" />
          </div>
          <div className='benefits-organisms__conteiner__item'>
            <img src="/img/SALUD.svg" alt="map location" />
          </div>
        </div>
      </div>
      <div className='certificate'>
        <h2>{title}</h2>
        <p>{text}</p>
        <div className='certificate__type'>
          <button className='certificate__button'>Cotiza gratis</button>
          <div>
            <img src="/img/whatss.svg" alt="whatsapp" />
            |
            <img src="/img/email-black.svg" alt="email" />
          </div>
        </div>
      </div>
    </>
  )
}

export default BenefictsAndWhoDifferent