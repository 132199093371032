import './styles.scss';

interface Props {
  DOFDate: string
  VigorDate: string
  responsible: string
  margin?: boolean
}

const NormInfo: React.FC<Props> = (props) => {
  const {
    DOFDate,
    VigorDate,
    responsible,
    margin = false
  } = props;

  return (
    <div className={`norm-info-dates norm-info-dates${margin ? '--margin' : ''}`}>
      <div className='norm-info-dates__item'>
        <h4>Fecha de publicación del DOF</h4>
        <img src="/img/dof.svg" alt="DOF" />
        <span>{DOFDate}</span>
      </div>
      <div className='norm-info-dates__item'>
        <h4>Fecha de entrada en vigor</h4>
        <img src="/img/vigor.svg" alt="vigor" />
        <span>{VigorDate}</span>
      </div>
      <div className='norm-info-dates__item'>
        <h4>Dependencia responsable</h4>
        <img src="/img/responsible.svg" alt="responsible" />
        <span>{responsible}</span>
      </div>
    </div>
  )
}

export default NormInfo