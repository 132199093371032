/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom051: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-141-SSA1/SCFI-2012 - Cosméticos';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org", "@type": "WebPage", "@id":
              "https://www.cecsatrade.mx/nom-141-ssa1-scfi-2012", "url":
              "https://www.cecsatrade.mx/nom-141-ssa1-scfi-2012", "name":
              "NOM-141-SSA1/SCFI-2012", "description": `La NOM-141-SCFI/SSA1-2012 establece los requisitos para el etiquetado comercial de productos cosméticos en México, asegurando que los consumidores reciban información clara sobre ingredientes, modo de uso, advertencias de seguridad, y origen del producto para garantizar su salud. y bienestar.`, "isPartOf": {
                "@id":
                  "https://www.cecsatrade.mx/#/schema/WebSite/1"
              }, "datePublished":
              "2023-01-31T08:08:40-06:00", "dateModified": "2023-01-31T08:43:03-06:00",
            "primaryImageOfPage": { "@id": "https://www.cecsatrade.mx/assets/141.svg" }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="La NOM-141-SCFI/SSA1-2012 establece los requisitos para el etiquetado comercial de productos cosméticos en México, asegurando que los consumidores reciban información clara sobre ingredientes, modo de uso, advertencias de seguridad, y origen del producto para garantizar su salud. y bienestar."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-141-SSA1/SCFI-2012 CÓSMETICOS</h1>
        <div className='norm-info__header norm-info__header--141'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>norma de productos cósmeticos preenvasados</b> establece los requisitos sanitarios como la información comercial que debe tener el correcto etiquetado para garantizar la seguridad de los consumidores y el cumplimiemto de estas regulaciones destinadas al consumidor  final.
              <br />
              <br />
              Esta <b>Norma Oficial Mexicana</b> al otorgar la información necesaria de su etiquetado  mantiene un carácter obligartorio en productos nacionales como de procedencia extranjera.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              La <b>NOM-141-SCFI/SSA1-2012</b> establece los requisitos para el <b>etiquetado comercial de productos cosméticos en México</b>, asegurando que los consumidores reciban información clara sobre ingredientes, modo de uso, advertencias de seguridad, y origen del producto para garantizar su salud. y bienestar.
            </p>
          </div>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/cosmeticos.png" alt="cosmeticos" />
          </div>
        </div><div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              La <b>NOM-141-SCFI/SSA1-2012</b>.  Etiquetado para productos cosméticos preenvasados. Etiquetado sanitario y comercial. Fue publicada en el <a href="https://www.dof.gob.mx/nota_detalle.php%3Fcodigo%3D5269348%26fecha%3D19/09/2012#gsc.tab=0" target='_blank'>Diario Oficial de la Federación</a> el 19 de septiembre de 2012, pero entró en vigor el 18 de diciembre de 2012.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/nota_detalle.php?codigo=5269348&fecha=19/09/2012#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación de la NOM-141</h2>
            <br />
            <p>
              <div className='norm-info__image-container__aplications-015'>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-141-1.svg" alt="cosmeticos" />
                  <span><b>Cósmeticos preenvasados </b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-141-2.svg" alt="perfumes" />
                  <span><b>Perfumes  y fragancias</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-141-3.svg" alt="cremas" />
                  <span><b>Cremas y lociones corporales</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-141-4.svg" alt="productos para cabello" />
                  <span><b>Productos para el cabello </b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-141-5.svg" alt="higiene personal" />
                  <span><b>Productos de higiene personal</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-141-6.svg" alt="esmaltes" />
                  <span><b>Esmaltes de uñas </b></span>
                </div>
              </div>
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-141-SCFI/SSA1-2012 no aplica a:</h2>
            <br />
            <p>La presente <b>Norma Oficial Mexicana</b> no es aplicable a lo siguiente:</p>
            <br />
            <div className='norm-info__image-container__text__data-item-container-004'>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-141-1.svg" alt="juguetes-armas" />
                <span><b>Medicamentos</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-141-2.svg" alt="juguetes-armas" />
                <span><b>Farmacéuticos</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-141-3.svg" alt="juguetes-armas" />
                <span><b>Cosméticos a granel </b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-141-4.svg" alt="juguetes-armas" />
                <span><b>Cosméticos sin envasar </b></span>
              </div>
            </div>
            <br />
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>

        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Productos cósmeticos </span>
            <p>Las sustancias o formulaciones destinadas a entrar en contacto con la piel con el objetivo de limpiar, perfumar, proteger o mejorar su apariencia.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Productos preenvasados </span>
            <p>Los productos cosméticos, una vez envasados, no pueden ser alterados sin que el envase sea abierto o modificado perceptiblemente.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Nomenclatura Internacional de Ingredientes Cosméticos</span>
            <p>Nomenclatura que puede usarse en la declaración de cada ingrediente utilizado en la elaboración de un producto cosmético. (NIIC-INCI)</p>
          </div>
          <h2>CONTENIDO DE LA ETIQUETA</h2>
          <br />
          <br />
          <p>El etiquetado de productos cosméticos en México debe cumplir con algunos requisitos clave para garantizar la claridad y seguridad de la información para los consumidores.</p>
          <br />
          <div className="norm-info__050-definitions__item">
            <span>Idioma</span>
            <p>Toda la información en la etiqueta debe estar en español. Puede incluirse en otros idiomas.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Legibilidad</span>
            <p>Los caracteres deben ser claros, visibles, fáciles de leer y en colores contrastantes.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Instrucciones de uso</span>
            <p>Los productos que lo requieran deben incluir instrucciones claras de uso en el envase o en un instructivo anexo.</p>
          </div>
          <br />
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--images">
            <img src="/img/141-logo-1.svg" alt="141-logo-1" />
            <img src="/img/141-logo-2.svg" alt="141-logo-2" />
            <img src="/img/141-logo-3.svg" alt="141-logo-3" />
            <img src="/img/141-logo-4.svg" alt="141-logo-4" />
          </div>
          <br />
          <h2>Información básica</h2>
          <br />
          <br />
          <p className='norm-info__image-container__text-left'>
            Nombre del producto (genérico y específico).
          </p>
          <p className='norm-info__image-container__text-right'>
            País de origen (por ejemplo: "Hecho en...").
          </p>
          <p className='norm-info__image-container__text-left'>
            Nombre y dirección del fabricante o responsable.
          </p>
          <p className='norm-info__image-container__text-right'>
            Número de lote para identificar la producción.
          </p>
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--column">
            <img src="/img/cosmeticos-1.png" alt="cosmeticos" />
            <div className="norm-info__015-definitions__item__container">
              <p>
                <b>Ingredientes</b>
                <br />
                Deben listarse en orden de cantidad, empezando por los de mayor concentración.
                <br />
                <br />
                <b>Advertencia</b>
                <br />
                Productos con ingredientes potencialmente riesgosos deben incluir advertencias.
              </p>
            </div>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Caducidad</span>
            <p>Productos con duración menor a 24 meses deben indicar la fecha de caducidad (mes y año). </p>
          </div>
          <p className='norm-info__image-container__text-center'>
            <br />
            <br />
            Garantiza que tu producto cumpla con las regulaciones para el mercado mexicano y brinda confiabilidad al consumidor.
            <br />
            <br />
          </p>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>

        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-141.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=4792407&fecha=13/10/1993" target='_blank'>NOM-002-SCFI-1993</a>, productos preenvasados-contenido neto tolerancias y métodos de verificación.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-141.png" alt="juguete" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a>, Sistema General de Unidades de Medida.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-141.png" alt="juguete" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0" target='_blank'>NOM-030-SCFI-2006</a>, Información comercial-Declaración de cantidad en la etiqueta. Especificaciones.
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='19/09/2012'
          VigorDate='18/12/2012'
          responsible='SECRETARIA DE SALUD y SECRETARIA DE ECONOMIA'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Qué tipo de productos regula la NOM-141?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La NOM-141 regula los productos cosméticos de cualquier capacidad preenvasados y con destino al consumidor final.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Qué sucede si un producto cosmético no cumple con la NOM-141?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo a la Ley Federal de la Metrología y Normalización, en el articulo 112, nos dice que si un producto no cumple con la NOM puede generar multas, clausura temporal o definitiva, que podrá ser parcial o total, arresto hasta por treinta y seis horas, suspensión o revocación de la autorización, aprobación, o registro según corresponda y suspensión o cancelación del documento donde consten los resultados de la evaluación de la conformidad, así como de la autorización del uso de contraseñas y marcas registradas.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Qué impacto tiene la NOM-141 en los productos importados?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Todos los productos cosméticos importados que se comercialicen en México deben cumplir con los mismos requisitos de etiquetado que los productos nacionales. Esto incluye tener la información en español y cumplir con las especificaciones sanitarias locales.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Qué beneficios ofrece la NOM-141 para los consumidores?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La NOM-141 garantiza que los consumidores tengan acceso a información completa y clara sobre los cosméticos que compran, lo que les permite tomar decisiones más informadas. Además, asegura que los productos cumplan con estándares de seguridad y calidad.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Realizan pruebas de seguridad  para cumplir con la NOM-141?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En  realidad existe un marco normativo robusto, que las empresas dedicadas a la fabricación, distribución y comercialización de productos cosméticos deben cumplir. Se encuentra contemplado en la Ley General de Salud y en el Reglamento de Control Sanitario por parte de la Secretaría de Salud y la COFEPRIS; en las leyes de protección al consumidor por parte de la PROFECO y en las correspondientes a procedimientos aduanales en materia de importación y exportación.
                  <br />
                  <br />
                  Es obligatorio realizar las pruebas de seguridad correspondientes. Por ello, los Estudios de Irritabilidad, Sensibilización y Microbiología son fundamentales en la investigación, desarrollo y control de los productos comercializados.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Utilizan animales para hacer la prueba de los cósmeticos?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  México ha realizado cambios en su legislación por lo que, en septiembre del 2021 el senado mexicano aprobó las reformas a la Ley General de Salud para prohibir el uso de animales en pruebas para productos y artículos cosméticos y en octubre del mismo año se publicó en el DOF las reformas a LGS.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿Cuáles son los ingredientes prohibidos o restringidos según la NOM-141?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Existen ingredientes los cuales muestran limitaciones y no deben sobre pasar de lo permitido, entre esas sustancias se encuentran:
                  <br />Acetona
                  <br />Ácido ascorbico y sus sales
                  <br />Ácido borico boratos
                  <br />Ácido formico y su sal de sodio
                  <br />Acido p-hidroxibenzoico y sus sales y sus ésteres
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Existen excepciones o productos que no estén cubiertos según la NOM-141?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Aunque existen excepciones específicas, es fundamental que cualquier producto que sea etiquetado o comercializado como "cosmético" en México cumpla con los requisitos generales de seguridad para no causar daño a la salud del consumidor. Además, es importante revisar si hay otras normativas aplicables, ya que algunos productos podrían estar sujetos a múltiples regulaciones dependiendo de su uso o ingredientes.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading9">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                  ¿Qué pasa si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la <b>NOM-141-SSA1/SCFI-2012</b> deberás de contactar a una <a href="https://www.cecsatrade.mx/" target='_blank'>Unidad de Inspección</a> para obtener una Constancia de Inspección.
                  <br />Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.
                  <br />Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br /><br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
          </ul>
        </div>

        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom051