/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react';
import { BenefictsAndWhoDifferent, ExcusiveAttention, JsonLd, NormInfo, References, ServiceSteps, ServicesToOneClick } from '../../components';
import './styles.scss';
import { Helmet } from 'react-helmet';

const Nom055: React.FC = () => {
  useEffect(() => {
    const title = document.getElementById('page-title');

    if (title) {
      title.innerHTML = 'NOM-235-SE-2020, Atún y bonita preenvasados-Denominación-Especificaciones-Información comercial y ymétodos de prueba';
    }
  }, []);

  return (
    <>
      <JsonLd
        data={
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://www.cecsatrade.mx/nom-235-se-2020",
            "url": "https://www.cecsatrade.mx/nom-235-se-2020",
            "name": "NOM-235-SE-2020",
            "description": "Conoce los requisitos de la NOM-235-SCFI-2020, que regula la información comercial y etiquetado de atún y bonita, asegurando claridad y transparencia para los consumidores en México.",
            "isPartOf": {
              "@id": "https://www.cecsatrade.mx/#/schema/WebSite/1"
            },
            "datePublished": "2023-08-17T08:08:40-06:00",
            "dateModified": "2023-08-17T08:43:03-06:00",
            "primaryImageOfPage": {
              "@id": "https://www.cecsatrade.mx/assets/235.svg"
            }
          }
        }
      />
      <Helmet>
        <meta
          name="description"
          content="Conoce los requisitos de la NOM-235-SCFI-2020, que regula la información comercial y etiquetado de atún y bonita, asegurando claridad y transparencia para los consumidores en México."
        />
      </Helmet>
      <section className='norm-info'>
        <div className='sub-header'>
          <div className='sub-header__spacer'></div>
          <div className='sub-header__spacer'></div>
          {/* <a className='sub-header__button' href="https://blog.cecsatrade.mx/" target='_blank' rel="noreferrer">Blog</a> */}
          <a
            className='sub-header__email'
            href="mailto:asesoria@cecsatrade.mx"
            target="_blank">
            <img loading="lazy" src="/img/mail.svg" alt="Mail" />
            |
            Mail de CECSA
          </a>
        </div>
        <h1 className='norm-info__title'>NOM-235-SE-2020 ATÚN Y BONITA</h1>
        <div className='norm-info__header norm-info__header--235'>
          <button onClick={() => {
            const element = document.getElementById("benefits");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }
          }}>Beneficios</button><a href='https://wa.me/message/YZR3TYWZRARDH1' target='_blank'>Quiero cumplir con la NOM</a>
          <button onClick={() => {
            const element = document.getElementById("preguntas-frecuentes");

            if (element) {
              element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
            }
          }}>Preguntas frecuentes</button>
        </div>
        <div className='norm-info__image-container'>
          {/* <img className='norm-info__image-container__img' loading="lazy" src="/img/nom-003-asset.svg" alt="NOM 003 Logo" /> */}
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Sé el primero en saber...</h2>
            <br />
            <p>
              La <b>Norma de atún y bonita</b> define la denominación, presentación, información comercial, especificaciones y métodos de prueba para estos productos envasados, garantizando una comercialización segura y una información clara y completa para el consumidor.
              <br />
              <br />
              Esta  Norma Oficial Mexicana es aplicable y obligatoria  a los productos de fabricación nacional o extranjera que se comercializan en el territorio nacional.
            </p>
          </div>
        </div>
        <div className='norm-info__image-container norm-info__image-container--black'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center norm-info__image-container__text--small-margin'>
            <p style={{ textAlign: 'left' }}>
              Conoce los requisitos de la <b>NOM-235-SCFI-2020</b>, que regula la <b>información comercial y etiquetado de atún y bonita</b>, asegurando claridad y transparencia para los consumidores en México.
            </p>
          </div>
          <div className='norm-info__image-container__img-container'>
            <img className='norm-info__image-container__img-container__image' loading="lazy" src="/img/lata-atun.png" alt="lata-atun" />
          </div>
        </div>
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Entrada en vigor</h2>
            <br />
            <p>
              <b>NOM-235-SE-2020, Atún y bonita preenvasados-Denominación-Especificaciones-Información comercial y métodos de prueba</b>, fue publicada en el Diario Oficial de la Federación el 18 de septiembre de 2020 pero entró en vigor a partir del 01 de abril de 2021.
            </p>
            <br />
            <a className='norm-info__image-container__anchorage' href="https://www.dof.gob.mx/nota_detalle.php?codigo=5269348&fecha=19/09/2012#gsc.tab=0" target='_blank'>Más información de esta NOM</a>
          </div>
        </div><img className='norm-info__ellipse' src="/img/ellipse-black.svg" alt="ellipse" />
        <div className='norm-info__image-container'>
          <div className='norm-info__image-container__text norm-info__image-container__text--center'>
            <h2>Aplicación de la NOM-235</h2>
            <br />
            <p>La <b>NOM 235</b> establece los requisitos de calidad sanitaria para garantizar la seguridad del atún y bonito procesados para consumo humano en México.</p>
            <br />
            <p>
              <div className='norm-info__image-container__aplications-015'>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-235-1.svg" alt="Atún" />
                  <span><b>Atún</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-235-2.svg" alt="Bonita" />
                  <span><b>Bonita</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-235-3.svg" alt="Atún congelado" />
                  <span><b>Atún congelado</b></span>
                </div>
                <div className='norm-info__image-container__aplications-015__item'>
                  <img loading="lazy" src="/img/aplication-235-4.svg" alt="Atún preenvasado" />
                  <span><b>Atún preenvasado</b></span>
                </div>
              </div>
            </p>
          </div>
        </div>
        <ExcusiveAttention />
        <div className='norm-info__image-container norm-info__image-container--blue-050'>
          <img className='norm-info__image-container__image-background-1' loading="lazy" src="/img/background-circle-blue-1.svg" alt="backghround 1" />
          <div className='norm-info__image-container__text'>
            <h2>La NOM-235-SE-2020 no aplica a:</h2>
            <br />
            <p>La presente <b>Norma Oficial Mexicana</b> no es aplicable a lo siguiente:</p>
            <br />
            <div className='norm-info__image-container__text__data-item-container-004'>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-235-1.svg" alt="Alimentos bajo otra NOM" />
                <span><b>Alimentos bajo otra NOM</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-235-2.svg" alt="Productos a Granel " />
                <span><b>Productos a Granel </b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-235-3.svg" alt="Productos Agrícolas" />
                <span><b>Productos Agrícolas</b></span>
              </div>
              <div className='norm-info__image-container__text__data-item' style={{ flexDirection: 'column' }}>
                <img className='norm-info__image-container__text__data-item__img' loading="lazy" src="/img/not-aplication-235-4.svg" alt="Carnes rojas y de pollo " />
                <span><b>Carnes rojas y de pollo  </b></span>
              </div>
            </div>
            <br />
          </div>
          <img className='norm-info__image-container__image-background-2' loading="lazy" src="/img/background-circle-blue-2.svg" alt="backghround 2" />
        </div>
        <div className='norm-info__image-container norm-info__image-container--black norm-info__image-container--margin norm-info__image-container--050' style={{ marginBottom: '30rem', overflow: 'visible', zIndex: '7' }}>
          <h2>DEFINICIONES</h2>
          <div className="norm-info__050-definitions__item">
            <span>Atún y bonita preenvasados </span>
            <p>Productos elaborados con la carne procedente de cualquiera de las especies de atún o bonita.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Caldo vegetal </span>
            <p>Caldo elaborado a partir de extractos de vegetales o leguminosas como ajo, apio, cebolla, col, espinaca, soja, perejil, pimiento, tomate, zanahoria, entre otros.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Envase</span>
            <p>Cualquier recipiente, o envoltura en el cual esté contenido el atún o bonita para su venta al consumidor.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Etiqueta</span>
            <p>Cualquier rótulo, marbete, imagen u otra descripción gráfica impresa, marcada o adherida al envase de productos de atún o bonito preenvasados.</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Masa drenada </span>
            <p>Cantidad de atún, bonito e ingredientes opcionales en un envase, después de que el líquido es removido mediante un método establecido.</p>
          </div>
          <h2>CONTENIDO DE LA ETIQUETA</h2>
          <br />
          <br />
          <p>La <b>NOM 235</b> debe ser complementada con la siguiente información en el etiquetado para poder cumplir con lo que se establece</p>
          <br />
          <h2>Denominación del Producto</h2>
          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--column">
            <div className="norm-info__015-definitions__item__container">
              <p>
                <br />
                Indicar si es atún o bonita
                <br />
                <br />
                Especificar la forma de presentación
                <br />
                <br />
                Mencionar el medio de cobertura (aceite, agua, etc.)
                <br />
                <br />
                Indicar el porcentaje de ingredientes opcionales en la masa drenada
              </p>
            </div>
            <img src="/img/lata_y_bolsa_de_atun.png" alt="lata y bolsa de atun" />
          </div>
          <br />
          <br />
          <h2>Producto y Etiqueta</h2>
          <br />
          <br />
          <div className="norm-info__050-definitions__item">
            <span>Color del Producto</span>
            <p>Describir su color, como "blanco" o "claro" y "oscuro" para otras species</p>
          </div>
          <div className="norm-info__050-definitions__item">
            <span>Veracidad</span>
            <p>La etiqueta no debe incluir imágenes o información que generen error sobre la forma de presentación.</p>
          </div>

          <div className="norm-info__015-definitions__item norm-info__015-definitions__item--images">
            <img src="/img/235-logo-1.svg" alt="235-logo-1" />
            <img src="/img/235-logo-2.svg" alt="235-logo-2" />
            <img src="/img/235-logo-3.svg" alt="235-logo-3" />
          </div>
          <br />
          <p>Este etiquetado garantiza que los consumidores reciban información clara y precisa sobre el atún o bonito preenvasado.</p>
          <img className='norm-info__050-definitions__footer' loading="lazy" src="/img/definitions-050-footer.svg" alt="footer" />
        </div>

        <BenefictsAndWhoDifferent
          title={'CERTIFICA TUS PRODUCTOS'}
          text={'Contactanos, nosotros te apoyamos en cada paso que debes realizar para cumplir conforme lo establecido de manera nacional en materia de comercial dentro de la industria.'}
        />
        <References
          items={[
            (
              <li>
                <img src="/img/list-item-235.png" alt="atun" />
                <a href="https://www.dof.gob.mx/nota_detalle_popup.php?codigo=5263188" target='_blank'>NOM-002-SCFI-2011</a>,  Productos preenvasados-Contenido neto-Tolerancias y métodos de verificación.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-235.png" alt="atun" />
                <a href="https://dof.gob.mx/nota_detalle.php?codigo=718870&fecha=27/11/2002#gsc.tab=0" target='_blank'>NOM-008-SCFI-2002</a>, Sistema general de unidades de medida.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-235.png" alt="atun" />
                <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=4936602&fecha=06/11/2006#gsc.tab=0" target='_blank'>NOM-030-SCFI- 2006</a>, Información comercial-Declaración de cantidad en la etiqueta-Especificaciones.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-235.png" alt="atun" />
                <a href="https://www.dof.gob.mx/normasOficiales/4010/seeco11_C/seeco11_C.htm" target='_blank'>NOM-051-SCFI/SSA1-2010</a>, Especificaciones generales de etiquetado para alimentos y bebidas no alcohólicas preenvasados-Información comercial y sanitaria.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-235.png" alt="atun" />
                <a href="https://www.dof.gob.mx/normasOficiales/3581/stps/stps.htm" target='_blank'>NOM-025-STPS-2008</a>, Condiciones de iluminación en los centros de trabajo.
              </li>
            ),
            (
              <li>
                <img src="/img/list-item-235.png" alt="atun" />
                <b>NMX-F-315-1978</b>, Determinación de la masa drenada o escurrida en alimentos envasados.
              </li>
            )
          ]}
        />
        <NormInfo
          DOFDate='18/09/2020'
          VigorDate='01/04/2021'
          responsible='SECRETARÍA DE ECONOMIA'
        />
        <div className='norm-info__questions' id='preguntas-frecuentes'>
          <h2 className="norm-info__container__sub-title">Preguntas frecuentes</h2>
          <ul className="accordion norm-info__container__not-transform-ul" id="accordion">
            <li className="accordion-item">
              <div id="heading1">
                <button className="accordion-button norm-info__container__accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                  ¿Cuál es la diferencia de Atún y Bonita?
                </button>
              </div>
              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Para comenzar, son los productos elaborados con la carne de cualquiera de las especies de atún o bonita, con diversos aditivos permitidos, con o sin medio de cobertura (como aceite de oliva, agua, aceite, aceite con agua, agua con aceite).
                  <br />
                  <br />
                  Pero, entre las diferencias que tienen ambos tipos de pescado, los distinguen ciertas características uno del otro.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading2">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                  ¿Quiénes deben cumplir con la NOM-235?
                </button>
              </div>
              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Esta Norma es aplicable a los productos de fabricación nacional o extranjera que se comercialicen en el territorio nacional.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading3">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                  ¿Cuáles son las sanciones en caso de no cumplirla?
                </button>
              </div>
              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordion">
                <div className="accordion-body">
                  De acuerdo con el ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior este tipo de productos solamente se podrá importar bajo el amparo de una constancia de inspección o constancia de conformidad emitida por una unidad de inspección acreditada y aprobada en los términos de la Ley de la Infraestructura de la Calidad.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading4">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  ¿Quiénes regulan la NOM-235?
                </button>
              </div>
              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordion">
                <div className="accordion-body">
                  La Secretaría de  Economía  es responsable de la creación, actualización y vigilancia del cumplimiento de la <b>NOM-235</b> en el ámbito comercial. Esta secretaría asegura que las etiquetas y especificaciones de los productos sean claras y adecuadas para los consumidores.
                  <br />
                  <br />
                  Además, la Procuraduría Federal del Consumidor (PROFECO), es quien se encarga de verificar en el mercado que los productos cumplan con los requisitos establecidos en la norma, como la correcta información en el etiquetado y la veracidad de las declaraciones comerciales.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading5">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  ¿Cómo y dónde podemos obtener esta certificación al cliente o productor?
                </button>
              </div>
              <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Puedo obtener esta certificacion por medio de una evaluacion de conformidad realizada por una Unidad de Inspección Acreditada
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading6">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  ¿Qué pasa si detienen mi producto en aduana?
                </button>
              </div>
              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordion">
                <div className="accordion-body">
                  En primer lugar, deberás de solicitar la fracción arancelaria del producto. Una vez que tengas la fracción arancelaria y corrobores que le solicitan el cumplimiento de la NOM deberás de contactar a una Unidad de Inspección para obtener una Constancia de Inspección.
                  <br />Debido a que el producto no puede etiquetarse en México, deberás de etiquetar en la aduana o regresar el producto con tu proveedor para que sea etiquetado.
                  <br />Te recomendamos analizar los costos de cada situación y evaluarlos.
                  <br />
                  <br />
                  Una vez que nos contactes, te brindaremos la asesoría y apoyo para que puedas liberar tu producto de la aduana. El proceso será rápido, cada minuto cuenta, por lo que te dejamos nuestro correo (contacto@cecsatrade.mx) y número de contacto (56 5019 0728)
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading7">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                  ¿El atún en comercialización es realmente atún y no soya?
                </button>
              </div>
              <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordion">
                <div className="accordion-body">
                  Si la lata de atún o bonita contiene soya, el producto preenvasado puede tener en su contenido hasta el 50% de masa drenada, si sobrepasa de esto se puede decir que si es de soya y no de atún.
                  <br /><br />
                  Además, puede contener ingredientes como:
                  <br /><br />
                  -Verdura
                  <br /> - Leguminosas
                  <br /> - Chile
                  <br /> - Mayonesa o aderezos
                  <br /> - Ensalada o mezcla de verduras, entre otras más.
                  <br /><br />
                  Para lo cual la PROFECO, en su revista del consumidor de marzo del año en curso, analizo las diversas marcas comerciales de atún a la venta en México para saber cuáles si cumplían con los requisitos de la norma.
                </div>
              </div>
            </li>
            <li className="accordion-item">
              <div id="heading8">
                <button className="accordion-button norm-info__container__accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                  ¿Mantienen un sabor diferente atún y bonita?
                </button>
              </div>
              <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordion">
                <div className="accordion-body">
                  El atún y el bonito mantienen sabores diferentes, algunas diferencias estan:
                  <br /><br />
                  - Atún: El atún suele tener un sabor más suave y delicado, con una textura carnosa que lo hace muy apreciado en una variedad de preparaciones, especialmente en crudo (como en sushi) o enlatado. Dependiendo de la especie (como el atún aleta amarilla o el atún rojo), puede variar ligeramente en sabor y textura, pero en general es más sutil.
                  <br /> - Bonito: El bonito, aunque similar, tiene un sabor más intenso y pronunciado. Suele ser un poco más fuerte, con un perfil más marcado, lo que lo hace ideal para preparaciones donde se busca un sabor más robusto. En algunas culturas, el bonito se usa deshidratado o en escamas para sazonar platos, ya que su sabor puede ser más concentrado.
                </div>
              </div>
            </li>
          </ul>
        </div>
        <img className='norm-info__ellipse' src="/img/ellipse-blue.svg" alt="ellipse" />
        <ServicesToOneClick />
        <ServiceSteps
          withNewsLetter={true}
        />
      </section>
    </>
  )
}

export default Nom055